import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, HourglassOutlined } from "@ant-design/icons";
import { Button, Col, Row } from "antd";

export default function OnboardingItem(props) {
    const {
        done,
        label,
        disabled,
        onClick,
    } = props;

    return (
        <Row gutter={[16, 16]}>
            <Col flex={0}>
                {done && (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                        }}
                    />
                )}
                {!done && (
                    <HourglassOutlined
                        style={{
                            color: grey.primary,
                        }}
                    />
                )}
            </Col>
            <Col flex={1}>
                {label}
            </Col>
            <Col>
                <Button
                    type="link"
                    onClick={() => onClick()}
                    disabled={disabled}
                >
                    Setup
                </Button>
            </Col>
        </Row>
    );
}