import { Form, Input, Switch } from "antd";
import InputFieldDefinition from "components/input-field-definition/InputFieldDefinition";
import TranslationsInput from "components/translations-input/TranslationsInput";
import { useEffect } from "react";

export default function ApartmentMetadataTypeForm(props) {
    const {
        form,
        apartmentMetadataType,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: apartmentMetadataType?.name,
            nameTranslations: apartmentMetadataType?.nameTranslations,
            specialType: !!apartmentMetadataType?.typeConfig,
            typeConfig: apartmentMetadataType?.typeConfig,
        })
    }, [form, apartmentMetadataType]);

    const specialType = Form.useWatch('specialType', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="nameTranslations"
                label="Translations"
            >
                <TranslationsInput />
            </Form.Item>
            <Form.Item
                name="specialType"
                label="Special type"
                valuePropName="checked"
            >
                <Switch />
            </Form.Item>
            {specialType && (
                <Form.Item
                    name="typeConfig"
                    label="Type configuration"
                >
                    <InputFieldDefinition />
                </Form.Item>
            )}
        </Form>
    );
}