import { Col, InputNumber, Row, Select } from "antd";
import { useEffect } from "react";

export default function GuestPricingInput(props) {
    const {
        maxGuestCount,
        currency,
        value,
        onChange,
    } = props;

    const guestCounts = [...Array(maxGuestCount).keys()].map(i => i + 1);

    useEffect(() => {
        if (value.length < maxGuestCount) {
            const diff = maxGuestCount - value.length;
            const missingOccupancies = [...Array(diff).keys()].map(i => value.length + i + 1);
            onChange([
                ...value,
                ...missingOccupancies
                    .map(occupancy => ({ occupancy, mode: 'value', value: 0 }))
            ]);
        }
        if (value.length > maxGuestCount) {
            onChange([...value.slice(0, maxGuestCount)]);
        }
    }, [value, onChange, maxGuestCount]);

    function getItem(guestCount) {
        return [...value ?? []].find(item => item.occupancy === guestCount);
    }

    function handleModeChange(guestCount, itemMode) {
        const newItem = { ...getItem(guestCount), mode: itemMode };
        const index = value.findIndex(item => item.occupancy === guestCount);
        const newValue = [...value];
        newValue[index] = newItem;
        onChange(newValue);
    }

    function handleValueChange(guestCount, itemValue) {
        const newItem = { ...getItem(guestCount), value: itemValue };
        const index = value.findIndex(item => item.occupancy === guestCount);
        const newValue = [...value];
        newValue[index] = newItem;
        onChange(newValue);
    }

    return (
        <Row gutter={[16, 16]}>
            {guestCounts.map(guestCount => (
                <Col
                    span={24}
                    key={guestCount}
                >
                    <Row
                        gutter={[16, 16]}
                        align="middle"
                    >
                        <Col>
                            <InputNumber
                                value={getItem(guestCount)?.value}
                                onChange={value => handleValueChange(guestCount, value)}
                                addonAfter={
                                    <Select
                                        value={getItem(guestCount)?.mode}
                                        onChange={value => handleModeChange(guestCount, value)}
                                        options={[
                                            {
                                                label: '%',
                                                value: 'percent',
                                            },
                                            {
                                                label: currency,
                                                value: 'value',
                                            },
                                        ]}
                                        style={{
                                            width: '70px',
                                        }}
                                    />
                                }
                            />
                        </Col>
                        <Col>
                            for {guestCount} {guestCount === 1 ? 'guest' : 'guests'}
                        </Col>
                    </Row>
                </Col>
            ))}
        </Row>
    );
}