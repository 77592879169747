import { Input, Select, Switch } from "antd";
import DatePicker from "components/DatePicker";
import { formatISO, parseISO } from "date-fns";

export default function GenericInput(props) {
    const {
        value,
        onChange,
        typeConfig,
    } = props;

    if (!typeConfig) {
        return (
            <Input
                value={value}
                onChange={e => onChange(e.target.value)}
            />
        );
    }

    if (typeConfig.type === 'list') {
        return (
            <Select
                value={value}
                onChange={value => onChange(value)}
                options={
                    [...typeConfig.options ?? []]
                        .map(option => ({
                            value: option,
                            label: option,
                        }))
                }
                style={{
                    width: '100%',
                }}
            />
        );
    }

    if (typeConfig.type === 'switch') {
        return (
            <Switch
                checked={value}
                onChange={value => onChange(value)}
            />
        );
    }

    if (typeConfig.type === 'date') {
        return (
            <DatePicker
                value={value && parseISO(value)}
                onChange={value => onChange(formatISO(value, { representation: 'date' }))}
            />
        );
    }
}