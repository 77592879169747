import { Form, Input } from "antd";
import ApartmentSelect from "apartments/components/apartment-select/ApartmentSelect";
import RadioGroupInput from "components/radio-group-input/RadioGroupInput";
import { useEffect } from "react";
import StorageSelect from "storages/components/storage-select/StorageSelect";

export default function ChecklistForm(props) {
    const {
        form,
        checklist,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: checklist?.name,
            apartments: {
                choice: checklist?.allApartments
                    ? 'connectToAll'
                    : checklist?.apartments.length > 0 ? 'connectToSelected' : 'doNotConnect',
                value: checklist?.apartments.map(apartment => apartment.id),
            },
            storages: {
                choice: checklist?.allStorages
                    ? 'connectToAll'
                    : checklist?.storages.length > 0 ? 'connectToSelected' : 'doNotConnect',
                value: checklist?.storages.map(storage => storage.id),
            },
        });
    }, [form, checklist]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="apartments"
                label="Apartments"
            >
                <RadioGroupInput
                    options={[
                        {
                            key: 'doNotConnect',
                            label: 'Do not connect',
                        },
                        {
                            key: 'connectToAll',
                            label: 'Connect to all',
                        },
                        {
                            key: 'connectToSelected',
                            label: 'Connect to selected',
                            input: (
                                <ApartmentSelect
                                    mode="multiple"
                                />
                            ),
                        }
                    ]}
                    defaultOption="doNotConnect"
                />
            </Form.Item>
            <Form.Item
                name="storages"
                label="Storages"
            >
                <RadioGroupInput
                    options={[
                        {
                            key: 'doNotConnect',
                            label: 'Do not connect',
                        },
                        {
                            key: 'connectToAll',
                            label: 'Connect to all',
                        },
                        {
                            key: 'connectToSelected',
                            label: 'Connect to selected',
                            input: (
                                <StorageSelect
                                    mode="multiple"
                                />
                            ),
                        }
                    ]}
                    defaultOption="doNotConnect"
                />
            </Form.Item>
        </Form>
    );
}