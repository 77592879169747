import { gql, useQuery } from "@apollo/client";
import { Alert, Card, Col, PageHeader, Result, Row } from "antd";
import ApartmentOnboarding from "apartments/components/apartment-onboarding/ApartmentOnboarding";
import { useAuth } from "auth";
import { useNavigate, useParams } from "react-router-dom";

const QUERY = gql`
    query GetApartmentForOnboardinView($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
        }
    }
`;

export default function OnboardApartmentView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();

    const { permissions } = useAuth();
    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    if (!permissions.includes('apartment:set')) {
        return (
            <Result
                status="error"
                title="No permissions"
                subTitle="You don't have permissions to onboard apartment"
            />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment"
            />
        );
    }

    return (
        <PageHeader
            title={data?.apartment?.name}
            onBack={() => navigate(-1)}
        >
            <Row gutter={[16, 16]}>
                <Col span={12}>
                    <Card loading={loading}>
                        <ApartmentOnboarding
                            apartmentId={apartmentId}
                        />
                    </Card>
                </Col>
            </Row>
        </PageHeader>
    );
}