import { Form } from "antd";
import { useEffect } from "react";
import TileDesigner from "tiles/components/tile-designer/TileDesigner";

export default function TileDesignerForm(props) {
    const {
        tiles,
        form,
        ...otherProps
    } = props;

    useEffect(() => {
        form.setFieldValue('tiles', tiles);
    }, [form, tiles]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="tiles"
                noStyle
            >
                <TileDesigner />
            </Form.Item>
        </Form>
    );
}