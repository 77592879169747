import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { Alert, Button, Card, Col, Input, InputNumber, message, PageHeader, Radio, Row, Space, Tooltip, Typography } from "antd";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import haversine from 'haversine';
import DatePicker from "components/DatePicker";
import { isBefore, startOfToday } from "date-fns";
import { PlusOutlined } from "@ant-design/icons";

const APARTMENTS_QUERY = gql`
    query GetApartmentNamesForCreateApartment {
        apartments {
            id
            name
            active
        }
    }
`;

const GEOCODING_QUERY = gql`
    query GetGeocodindResultsForCreateApartment($query: String!) {
        geocoding(query: $query) {
            id
            label
            latitude
            longitude
        }
    }
`;

const STORAGES_QUERY = gql`
    query GetStoragesForCreateApartment {
        storages {
            id
            name
            latitude
            longitude
        }
    }
`;

const MUTATION = gql`
    mutation CreateApartmentNew($input: CreateApartmentNewInput!) {
        createApartmentNew(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
            }
            syncApartmentUpTask {
                id
            }
            recalculateApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function CreateApartmentViewNew() {
    const navigate = useNavigate();

    const [name, setName] = useState();
    const [duplicatedApartment, setDuplicatedApartment] = useState();
    const [nameNextLoading, setNameNextLoading] = useState(false);
    const [afterNameStage, setAfterNameStage] = useState(false);

    const [address, setAddress] = useState();
    const [addressEditing, setAddressEditing] = useState(false);
    const [addressSearchLoading, setAddressSearchLoading] = useState(false);
    const [availableAddresses, setAvailableAddresses] = useState();

    const [pickedAddress, setPickedAddress] = useState();
    const [pickedStorage, setPickedStorage] = useState();
    const [maxGuestCount, setMaxGuestCount] = useState();
    const [availability, setAvailability] = useState('immediately');
    const [availableFrom, setAvailableFrom] = useState();

    const [errorName, setErrorName] = useState(false);
    const [errorAddress, setErrorAddress] = useState(false);
    const [errorMaxGuestCount, setErrorMaxGuestCount] = useState(false);
    const [errorAvailability, setErrorAvailability] = useState(false);

    const [getApartments] = useLazyQuery(APARTMENTS_QUERY);
    const [getGeocodingResults] = useLazyQuery(GEOCODING_QUERY);
    const [getStorages] = useLazyQuery(STORAGES_QUERY);
    const [createApartment] = useMutation(MUTATION);

    function handleNameNext() {
        async function handle() {
            setDuplicatedApartment();

            try {
                const apartmentsResponse = await getApartments();

                const otherApartment = [...apartmentsResponse.data.apartments ?? []]
                    .find(apartment => apartment.name === name);

                if (otherApartment) {
                    setDuplicatedApartment(otherApartment);
                    return;
                }

                const geocodingResponse = await getGeocodingResults({
                    variables: {
                        query: name,
                    },
                });

                if (!geocodingResponse.data.geocoding || geocodingResponse.data.geocoding.length === 0) {
                    setAddressEditing(true);
                    return;
                }
                if (geocodingResponse.data.geocoding.length === 1) {
                    const pickedAddress = geocodingResponse.data.geocoding[0]
                    setPickedAddress(pickedAddress);

                    const storagesResponse = await getStorages();

                    const storages = [...storagesResponse.data.storages ?? []]
                        .sort((a, b) => haversine(a, pickedAddress) - haversine(b, pickedAddress));

                    const closestStorage = storages?.[0];

                    setPickedStorage(closestStorage?.id);
                }
                if (geocodingResponse.data.geocoding.length > 1) {
                    setAddress(name);
                    setAddressEditing(true);
                    setAvailableAddresses(geocodingResponse.data.geocoding);
                }
            } catch {
                message.error("Network error");
                return;
            }
        }

        setNameNextLoading(true);

        handle()
            .then(() => {
                setNameNextLoading(false);
                setAfterNameStage(true);
            });
    }

    function handleAddressSearch() {
        async function handle() {
            setPickedAddress();
            setAvailableAddresses();

            try {
                const geocodingResponse = await getGeocodingResults({
                    variables: {
                        query: address,
                    },
                });

                if (!geocodingResponse.data.geocoding || geocodingResponse.data.geocoding.length === 0) {
                    setAddressEditing(false);
                    return;
                }
                if (geocodingResponse.data.geocoding.length === 1) {
                    const pickedAddress = geocodingResponse.data.geocoding[0]
                    setPickedAddress(pickedAddress);

                    const storagesResponse = await getStorages();

                    const storages = [...storagesResponse.data.storages ?? []]
                        .sort((a, b) => haversine(a, pickedAddress) - haversine(b, pickedAddress));

                    const closestStorage = storages?.[0];

                    setPickedStorage(closestStorage?.id);
                    setAddressEditing(false);
                }
                if (geocodingResponse.data.geocoding.length > 1) {
                    setAvailableAddresses(geocodingResponse.data.geocoding);
                }
            } catch {
                message.error("Network error");
                return;
            }
        }

        setAddressSearchLoading(true);

        handle()
            .then(() => {
                setAddressSearchLoading(false);
            });
    }

    function handleChooseAddress(item) {
        async function handle() {
            try {
                setPickedAddress(item);

                const storagesResponse = await getStorages();

                const storages = [...storagesResponse.data.storages ?? []]
                    .sort((a, b) => haversine(a, item) - haversine(b, item));

                const closestStorage = storages?.[0];

                setPickedStorage(closestStorage?.id);
                setAddressEditing(false);

            } catch {
                message.error("Network error");
            }
        }

        handle();
    }

    function handleSetAvailability(value) {
        setAvailability(value);
        if (value !== 'date') {
            setAvailableFrom();
        }
    }

    function handleCreate() {
        setErrorName(false);
        setErrorAddress(false);
        setErrorMaxGuestCount(false);
        setErrorAvailability(false);

        let hasErrors = false;

        if (!name || name.length === 0) {
            setErrorName(true);
            hasErrors = true;
        }
        if (!pickedAddress) {
            setErrorAddress(true);
            hasErrors = true;
        }
        if (!maxGuestCount) {
            setErrorMaxGuestCount(true);
            hasErrors = true;
        }
        if (!availability) {
            setErrorAvailability(true);
            hasErrors = true;
        }
        else {
            if (availability === 'date' && !availableFrom) {
                setErrorAvailability(true);
                hasErrors = true;
            }
        }

        if (hasErrors) {
            return;
        }

        createApartment({
            variables: {
                input: {
                    name,
                    latitude: pickedAddress.latitude,
                    longitude: pickedAddress.longitude,
                    storageId: pickedStorage,
                    maxGuestCount,
                    availableFrom: {
                        immediately: new Date(),
                        date: availableFrom,
                        unknown: null,
                    }[availability],
                },
            },
        });
    }

    return (
        <PageHeader
            title="Create apartment"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Row
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col span={6}>
                                        <Row justify="end">
                                            <Col>
                                                <Typography.Text type="secondary">
                                                    Name
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Input
                                            value={name}
                                            onChange={e => setName(e.target.value)}
                                            status={errorName && "error"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            {duplicatedApartment && (
                                <Col span={12} offset={6}>
                                    {duplicatedApartment.active && (
                                        <Alert
                                            type="error"
                                            showIcon
                                            message="Apartment with given name already exists. Choose different name please."
                                        />
                                    )}
                                    {!duplicatedApartment.active && (
                                        <Alert
                                            type="info"
                                            message="Apartment with given name already exists, but it's deactivated. You can edit it and activate"
                                            action={
                                                <Link to={`/apartments/${duplicatedApartment.id}/edit`}>
                                                    Edit apartment
                                                </Link>
                                            }
                                        />
                                    )}
                                </Col>
                            )}
                            {!afterNameStage && (
                                <Col span={12} offset={6}>
                                    <Row justify="end">
                                        <Col>
                                            {name?.length > 0 && (
                                                <Button
                                                    type="primary"
                                                    onClick={() => handleNameNext()}
                                                    loading={nameNextLoading}
                                                >
                                                    Next
                                                </Button>
                                            )}
                                            {(!name || name.length === 0) && (
                                                <Tooltip title="You have to provide name">
                                                    <Button disabled>
                                                        Next
                                                    </Button>
                                                </Tooltip>
                                            )}
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                        </Row>
                    </Col>
                    {afterNameStage && (
                        <>
                            <Col span={24}>
                                <Row
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col span={6}>
                                        <Row justify="end">
                                            <Col>
                                                <Typography.Text type="secondary">
                                                    Address
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        {!addressEditing && (
                                            <Row
                                                gutter={[16, 16]}
                                                justify="space-between"
                                                align="middle"
                                                wrap={false}
                                            >
                                                <Col>
                                                    {pickedAddress && (
                                                        <Typography.Text>
                                                            {pickedAddress.label}
                                                        </Typography.Text>
                                                    )}
                                                    {!pickedAddress && (
                                                        <Typography.Text
                                                            disabled
                                                            type={errorAddress && "danger"}
                                                        >
                                                            Undefined
                                                        </Typography.Text>
                                                    )}
                                                </Col>
                                                <Col>
                                                    <Button
                                                        type="link"
                                                        size="small"
                                                        onClick={() => setAddressEditing(true)}
                                                    >
                                                        Change
                                                    </Button>
                                                </Col>
                                            </Row>
                                        )}
                                        {addressEditing && (
                                            <Input.Search
                                                value={address}
                                                onChange={e => setAddress(e.target.value)}
                                                onSearch={() => handleAddressSearch()}
                                                loading={addressSearchLoading}
                                                status={errorAddress && "error"}
                                            />
                                        )}
                                    </Col>
                                </Row>
                            </Col>
                            {!pickedAddress && availableAddresses && (
                                <Col span={24}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={12} offset={6}>
                                            <Row gutter={[16, 16]}>
                                                {availableAddresses.map(availableAddress => (
                                                    <Col
                                                        span={24}
                                                        key={availableAddress.id}
                                                    >
                                                        <Row
                                                            gutter={[16, 16]}
                                                            justify="space-between"
                                                            align="middle"
                                                            wrap={false}
                                                        >
                                                            <Col>
                                                                <Typography.Text>
                                                                    {availableAddress.label}
                                                                </Typography.Text>
                                                            </Col>
                                                            <Col>
                                                                <Button
                                                                    type="link"
                                                                    size="small"
                                                                    onClick={() => handleChooseAddress(availableAddress)}
                                                                >
                                                                    Choose
                                                                </Button>
                                                            </Col>
                                                        </Row>
                                                    </Col>
                                                ))}
                                            </Row>
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col span={24}>
                                <Row
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col span={6}>
                                        <Row justify="end">
                                            <Col>
                                                <Typography.Text type="secondary">
                                                    Storage
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <StorageSelect
                                            value={pickedStorage}
                                            onChange={value => setPickedStorage(value)}
                                            showDistance
                                            distancePoint={pickedAddress}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row
                                    gutter={[16, 16]}
                                    align="middle"
                                >
                                    <Col span={6}>
                                        <Row justify="end">
                                            <Col>
                                                <Typography.Text type="secondary">
                                                    Capacity
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <InputNumber
                                            value={maxGuestCount}
                                            onChange={value => setMaxGuestCount(value)}
                                            min={1}
                                            max={100}
                                            addonAfter={maxGuestCount > 1 ? 'guests' : 'guest'}
                                            status={errorMaxGuestCount && "error"}
                                        />
                                    </Col>
                                </Row>
                            </Col>
                            <Col span={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={6}>
                                        <Row justify="end">
                                            <Col>
                                                <Typography.Text type="secondary">
                                                    Availability
                                                </Typography.Text>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={12}>
                                        <Radio.Group
                                            value={availability}
                                            onChange={e => handleSetAvailability(e.target.value)}
                                        >
                                            <Space direction="vertical">
                                                <Radio value="immediately">
                                                    Available immediately
                                                </Radio>
                                                <Radio value="date">
                                                    From a specific date
                                                </Radio>
                                                <Radio value="unknown">
                                                    I don't know when
                                                </Radio>
                                            </Space>
                                        </Radio.Group>
                                    </Col>
                                </Row>
                            </Col>
                            {availability === 'date' && (
                                <Col span={24}>
                                    <Row
                                        gutter={[16, 16]}
                                        align="middle"
                                    >
                                        <Col span={6}>
                                            <Row justify="end">
                                                <Col>
                                                    <Typography.Text type="secondary">
                                                        Available from
                                                    </Typography.Text>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={12}>
                                            <DatePicker
                                                value={availableFrom}
                                                onChange={value => setAvailableFrom(value)}
                                                disabledDate={date => isBefore(date, startOfToday())}
                                                allowClear={false}
                                                status={errorAvailability && "error"}
                                            />
                                        </Col>
                                    </Row>
                                </Col>
                            )}
                            <Col span={24}>
                                <Row justify="end">
                                    <Col>
                                        <Button
                                            type="primary"
                                            icon={<PlusOutlined />}
                                            onClick={() => handleCreate()}
                                        >
                                            Create
                                        </Button>
                                    </Col>
                                </Row>
                            </Col>
                        </>
                    )}
                </Row>
            </Card>
        </PageHeader>
    );
}