import { blue } from "@ant-design/colors";
import { CheckCircleFilled, CheckCircleOutlined } from "@ant-design/icons";
import classNames from "classnames";
import React, { useEffect } from "react";
import { Typography } from "antd";
import "../radio-group/style.css";

export default function RadioGroupInput(props) {
    const {
        options,
        defaultOption,
        value,
        onChange,
    } = props;

    useEffect(() => {
        onChange({
            choice: defaultOption,
            value: options.find(option => option.key === defaultOption)?.defaultValue,
        });
    }, []);

    function handleOptionChange(option) {
        if (value?.choice !== option.key) {
            onChange({
                choice: option.key,
                value: option.defaultValue,
            });
        }
    }

    function handleInputOnChange(inputValue) {
        onChange({
            ...value,
            value: inputValue,
        });
    }

    return (
        <div className="radio-group-container">
            {options.map(option => (
                <div
                    className={classNames({
                        'radio-group-option-container': true,
                        'radio-group-option-container-active': option.key === value?.choice,
                    })}
                    onClick={() => handleOptionChange(option)}
                    key={option.key}
                >
                    <div className="radio-group-radio">
                        {option.key === value?.choice && (
                            <CheckCircleFilled
                                style={{
                                    color: blue.primary,
                                    fontSize: '16px',
                                }}
                            />
                        )}
                        {option.key !== value?.choice && (
                            <CheckCircleOutlined
                                style={{
                                    color: blue.primary,
                                    fontSize: '16px',
                                }}
                            />
                        )}
                    </div>
                    <div className="radio-group-option-content">
                        <div className="radio-group-option-label">
                            <Typography.Text>
                                {option.label}
                            </Typography.Text>
                        </div>
                        {option.description && (
                            <div className="radio-group-option-description">
                                <Typography.Text type="secondary">
                                    {option.description}
                                </Typography.Text>
                            </div>
                        )}
                        {option.key === value?.choice && option.input && (
                            <div className="radio-group-input">
                                {React.cloneElement(option.input, { value: value?.value, onChange: handleInputOnChange })}
                            </div>
                        )}
                    </div>
                </div>
            ))}
        </div>
    );
}