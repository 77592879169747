import { Select } from "antd";

export default function TimeSelect(props) {
    const {
        value,
        onChange,
        startTime,
        endTime,
        minutesInterval,
    } = props;

    const [startDateHours, startDateMinutes] = startTime.split(':');
    const [endDateHours, endDateMinutes] = endTime.split(':');
    const startMinutes = parseInt(startDateHours) * 60 + parseInt(startDateMinutes);
    const endMinutes = parseInt(endDateHours) * 60 + parseInt(endDateMinutes);
    const interval = minutesInterval || 1;

    const options = [];
    for (let minutes = startMinutes; minutes <= (endMinutes + (endMinutes < startMinutes ? 60 * 24 : 0)); minutes += interval) {
        const timeHours = Math.floor(minutes / 60) % 24;
        const timeMinutes = minutes % 60;
        const timeStr = `${timeHours.toString().padStart(2, '0')}:${timeMinutes.toString().padStart(2, '0')}`;
        options.push({
            label: timeStr,
            value: timeStr,
        });
    }

    return (
        <Select
            options={options}
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '180px',
            }}
        />
    )
}