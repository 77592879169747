import { Alert, Button, Card, Col, Row, Space, Table, Tag } from 'antd';
import { gql, useQuery } from '@apollo/client';
import { Link } from 'react-router-dom';
import { useAuth } from 'auth';
import Tags from './columns/Tags';
import BaseGuestCount from './columns/BaseGuestCount';
import ExtraGuestFee from './columns/ExtraGuestFee';
import { compareAsc, parse, startOfToday } from 'date-fns';

const QUERY = gql`
    query GetApartmentsForApartmentsTable {
        apartments {
            id
            active
            name
            tags
            storage {
                id
                name
            }
            cleaningPrice
            maxGuestCount
            calendarLength
            cutOffTime
        }
    }
`;

export default function ApartmentsTable(props) {
    const {
        apartmentIds,
        onlyActive,
        displayedColumns,
        selectedApartments,
        onSelected,
    } = props;

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Card loading />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartments"
            />
        );
    }

    function filterApartmentIds(apartment) {
        if (apartmentIds !== undefined) {
            return apartmentIds.includes(apartment.id);
        }
        return true;
    }

    function filterOnlyActive(apartment) {
        if (onlyActive !== undefined && onlyActive !== false) {
            return apartment.active;
        }
        return true;
    }

    const columns = [
        {
            title: 'Tags',
            key: 'tags',
            render: apartment => <Tags tags={[...apartment.tags ?? []]} />,
            filters: user.organization.apartmentTags.map(tag => ({
                text: tag,
                value: tag,
            })),
            onFilter: (tag, apartment) => [...apartment.tags ?? []].includes(tag),
        },
        {
            title: 'Storage',
            key: 'storage',
            render: apartment => apartment.storage?.name,
            sorter: (a, b) => a.storage?.name.localeCompare(b.storage?.name),
        },
        {
            title: 'Cleaning price',
            key: 'cleaningPrice',
            render: apartment => `${apartment.cleaningPrice} ${user.organization.currency}`,
            sorter: (a, b) => (a.cleaningPrice ?? 0) - (b.cleaningPrice ?? 0),
            align: 'right',
        },
        {
            title: 'Maximum guest count',
            key: 'maxGuestCount',
            render: apartment => `${apartment.maxGuestCount} guests`,
            sorter: (a, b) => (a.maxGuestCount ?? 0) - (b.maxGuestCount ?? 0),
            align: 'right',
        },
        {
            title: 'Base guest count',
            key: 'increasePriceAbove',
            render: apartment => (
                <BaseGuestCount
                    increasePriceAbove={apartment.increasePriceAbove ?? 0}
                />
            ),
            sorter: (a, b) => (a.increasePriceAbove ?? 0) - (b.increasePriceAbove ?? 0),
            align: 'right',
        },
        {
            title: 'Extra guest fee',
            key: 'increasePriceBy',
            render: apartment => (
                <ExtraGuestFee
                    increasePriceBy={apartment.increasePriceBy ?? 0}
                />
            ),
            sorter: (a, b) => (a.increasePriceBy ?? 0) - (b.increasePriceBy ?? 0),
            align: 'right',
        },
        {
            title: 'Calendar length',
            key: 'calendarLength',
            render: apartment => `${apartment.calendarLength} days`,
            sorter: (a, b) => (a.calendarLength ?? 0) - (b.calendarLength ?? 0),
            align: 'right',
        },
        {
            title: 'Cut off time',
            key: 'cutOffTime',
            render: apartment => apartment.cutOffTime,
            sorter: (a, b) => compareAsc(parse(a.cutOffTime ?? '00:00', 'HH:mm', startOfToday()), parse(b.cutOffTime || '00:00', 'HH:mm', startOfToday())),
            align: 'right',
        },
        {
            title: 'Default price config min price',
            key: 'defaultPriceConfigMinPrice',
            render: apartment => apartment.defaultPriceConfigMinPrice,
            sorter: (a, b) => (a.defaultPriceConfigMinPrice ?? 0) - (b.defaultPriceConfigMinPrice ?? 0),
            align: 'right',
        },
        {
            title: 'Default price config delta',
            key: 'defaultPriceConfigDelta',
            render: apartment => apartment.defaultPriceConfigDelta,
            sorter: (a, b) => (a.defaultPriceConfigDelta ?? 0) - (b.defaultPriceConfigDelta ?? 0),
            align: 'right',
        },
        {
            title: 'Default price config slope',
            key: 'defaultPriceConfigSlope',
            render: apartment => apartment.defaultPriceConfigSlope,
            sorter: (a, b) => (a.defaultPriceConfigSlope ?? 0) - (b.defaultPriceConfigSlope ?? 0),
            align: 'right',
        },
    ];

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Table
                    columns={[
                        {
                            title: 'Name',
                            key: 'name',
                            render: apartment => (
                                <Space>
                                    <Link to={`/apartments/${apartment.id}`}>
                                        {apartment.name}
                                    </Link>
                                    {!apartment.active && (
                                        <Tag>
                                            inactive
                                        </Tag>
                                    )}
                                </Space>
                            ),
                            sorter: (a, b) => a.name.localeCompare(b.name),
                            defaultSortOrder: 'ascend',
                        },
                        ...columns.filter(column => displayedColumns.includes(column.key)),
                    ]}
                    dataSource={
                        [...data?.apartments ?? []]
                            .filter(filterApartmentIds)
                            .filter(filterOnlyActive)
                    }
                    rowKey="id"
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedApartments,
                        onChange: value => onSelected(value),
                    }}
                    size="small"
                    pagination={false}
                />
            </Col>
        </Row>
    );
}