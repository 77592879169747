import { gql, useLazyQuery } from "@apollo/client";
import { Form } from "antd";
import ApartmentLocationPicker from "apartments/components/apartment-location-picker/ApartmentLocationPicker";
import TimezoneSelect from "components/TimezoneSelect";
import { useEffect } from "react";
import StorageSelect from "storages/components/storage-select/StorageSelect";
import haversine from 'haversine';
import InputDefaultWrapper from "components/InputDefaultWrapper";
import { useAuth } from "auth";
import { validate } from "graphql";

const STORAGES_QUERY = gql`
    query GetStoragesForLocationOnboardingForm {
        storages {
            id
            latitude
            longitude
        }
    }
`;

export default function ApartmentLocationSettingsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    const { user } = useAuth();
    const [getStorages] = useLazyQuery(STORAGES_QUERY);
    const location = Form.useWatch('location', form);

    useEffect(() => {
        if (location && !apartment?.storage?.id) {
            getStorages()
                .then(response => {
                    const storages = [...response.data.storages ?? []]
                        .sort((a, b) => haversine(a, location) - haversine(b, location));
                    const closestStorage = storages?.[0];
                    form.setFieldValue('storageId', closestStorage?.id);
                });
        }
    }, [location, form, getStorages, apartment]);

    useEffect(() => {
        if (apartment?.address) {
            form?.setFieldValue('location', {
                address: apartment?.address,
                latitude: apartment?.latitude,
                longitude: apartment?.longitude,
            });
        }
        if (apartment?.storage?.id) {
            form?.setFieldValue('storageId', apartment.storage.id);
        }
        if (apartment?.timezone) {
            form?.setFieldValue('timezone', apartment.timezone);
        }
    }, [form, apartment]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="location"
                label="Location"
                rules={[
                    { required: true, message: 'Location is required' },
                    {
                        validator(_, value) {
                            if (!value?.latitude || !value?.longitude) {
                                return Promise.reject(new Error('Please pick location'))
                            }
                            return Promise.resolve();
                        }
                    },
                ]}
            >
                <ApartmentLocationPicker
                    initialAddress={apartment?.name}
                />
            </Form.Item>
            <Form.Item
                name="storageId"
                label="Storage"
                rules={[{ required: true, message: 'Storage is required' }]}
            >
                <StorageSelect
                    showDistance
                    distancePoint={location}
                />
            </Form.Item>
            <Form.Item
                name="timezone"
                label="Timezone"
                rules={[{ required: true, message: 'Timezone is required' }]}
            >
                <InputDefaultWrapper
                    defaultValue={user.organization.timezone}
                    disabled={apartment?.timezone}
                    description="From organization defaults"
                >
                    <TimezoneSelect />
                </InputDefaultWrapper>
            </Form.Item>
        </Form>
    );
}