import { useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Col, Form, List, Modal, Row, Skeleton } from "antd";
import ListItem from "./ListItem";
import ApartmentMetadataTypeForm from "organizations/forms/ApartmentMetadataTypeForm";

const QUERY = gql`
    query GetOrganizationApartmentMetadataTypes {
        organization(organizationId: "self") {
            id
            apartmentMetadataTypes {
                id
                name
                nameTranslations
                typeConfig
            }
        }
    }
`;

const CREATE_MUTATION = gql`
    mutation CreateApartmentMetadataType($input: CreateApartmentMetadataTypeInput!) {
        createApartmentMetadataType(input: $input) {
            error {
                type
                message
            }
            apartmentMetadataType {
                id
                name
                nameTranslations
                typeConfig
            }
        }
    }
`;

export default function ApartmentMetadataTypesList() {
    const { data, loading, error } = useQuery(QUERY);

    const [createApartmentMetadataType, { loading: createLoading }] = useMutation(CREATE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: data?.organization?.id,
                }),
                fieldName: 'apartmentMetadataTypes',
            });
        },
    });

    const [modalOpen, setModalOpen] = useState(false);
    const [form] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment metadata types"
            />
        );
    }

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                createApartmentMetadataType({
                    variables: {
                        input: {
                            name: values.name,
                            nameTranslations: values.nameTranslations,
                            typeConfig: values.typeConfig,
                        },
                    },
                })
                    .then(() => {
                        setModalOpen(false);
                    })
            });
    }

    return (
        <List
            dataSource={
                [...data?.organization?.apartmentMetadataTypes ?? []]
                    .sort((a, b) => a.name.localeCompare(b.name))
            }
            renderItem={apartmentMetadataType => (
                <ListItem
                    apartmentMetadataType={apartmentMetadataType}
                    organizationId={data?.organization?.id}
                />
            )}
            footer={
                <Row justify="end">
                    <Col>
                        <Button
                            type="primary"
                            icon={<PlusOutlined />}
                            onClick={() => setModalOpen(true)}
                        >
                            Create apartment metadata type
                        </Button>
                        <Modal
                            title="Create apartment metadata type"
                            width={800}
                            open={modalOpen}
                            destroyOnClose
                            onCancel={() => setModalOpen(false)}
                            onOk={() => handleCreate()}
                            okText="Create"
                            okButtonProps={{
                                icon: <PlusOutlined />,
                                loading: createLoading,
                            }}
                        >
                            <ApartmentMetadataTypeForm
                                form={form}
                                labelCol={{
                                    span: 6,
                                }}
                                wrapperCol={{
                                    span: 18,
                                }}
                                preserve={false}
                            />
                        </Modal>
                    </Col>
                </Row>
            }
        />
    );
}