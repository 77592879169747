import { gql, useQuery } from "@apollo/client";
import { Alert, Form, Input, Skeleton } from "antd";
import GenericInput from "components/generic-input/GenericInput";

const QUERY = gql`
    query GetApartmentMetadataTypesForForm {
        organization(organizationId: "self") {
            id
            apartmentMetadataTypes {
                id
                name
                typeConfig
            }
        }
    }
`;

export default function ApartmentOnboardingMetadataFullForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load apartment metadata types"
            />
        );
    }

    const metadataTypes = [...data?.organization?.apartmentMetadataTypes ?? []]
        .sort((a, b) => a.name.localeCompare(b.name));

    return (
        <Form
            form={form}
            {...otherProps}
        >
            {metadataTypes.map(metadataType => (
                <Form.Item
                    name={metadataType.id}
                    label={metadataType.name}
                    key={metadataType.id}
                >
                    <GenericInput
                        typeConfig={metadataType.typeConfig}
                    />
                </Form.Item>
            ))}
        </Form>
    );
}