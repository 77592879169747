import { EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, message, Modal } from "antd";
import { useState } from "react";
import TileDesignerForm from "tiles/forms/TileDesignerForm";

const MUTATION = gql`
    mutation UpdateOrganizationTiles($input: UpdateOrganizationInput!) {
        updateOrganization(input: $input) {
            organization {
                id
                apartmentTiles
                dashboardTiles
            }
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        label,
        tiles,
        organizationId,
        propName,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [updateOrganization, { loading: updateLoading }] = useMutation(MUTATION);
    const [form] = Form.useForm();

    function handleSave() {
        form
            .validateFields()
            .then(values => {
                updateOrganization({
                    variables: {
                        input: {
                            organizationId: organizationId,
                            [propName]: values.tiles,
                        },
                    },
                })
                    .then(response => {
                        if (response.data.updateOrganization.error) {
                            message.error("Failed to update tiles");
                        }
                        else {
                            message.success("Tiles updated");
                        }
                    })
                    .catch(() => {
                        message.error("Network error");
                    })
                    .finally(() => {
                        setModalOpen(false);
                    });
            })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    key="edit"
                    onClick={() => setModalOpen(true)}
                />
            ]}
        >
            <List.Item.Meta
                title={label}
            />
            <Modal
                title={`Edit tiles - ${label}`}
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                onOk={() => handleSave()}
                okText="Save"
                okButtonProps={{
                    loading: updateLoading,
                    icon: <SaveOutlined />,
                }}
                width={1000}
                destroyOnClose
            >
                <TileDesignerForm
                    tiles={tiles}
                    form={form}
                    preserve={false}
                />
            </Modal>
        </List.Item>
    );
}