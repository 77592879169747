import { Form, InputNumber } from "antd";
import { useAuth } from "auth";
import InputDefaultWrapper from "components/InputDefaultWrapper";
import { useEffect } from "react";

export default function ApartmentCalendarSettingsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    const { user } = useAuth();

    useEffect(() => {
        if (apartment?.calendarLength) {
            form?.setFieldValue('calendarLength', apartment.calendarLength);
        }
    }, [form, apartment]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="calendarLength"
                label="Calendar length"
                rules={[{ required: true, message: 'Calendar length is required' }]}
            >
                <InputDefaultWrapper
                    defaultValue={user?.organization?.defaultApartmentCalendarLength}
                    renderValue={value => `${value} days`}
                    disabled={apartment?.calendarLength}
                    description="From organization defaults"
                >
                    <InputNumber
                        min={100}
                        addonAfter="days"
                    />
                </InputDefaultWrapper>
            </Form.Item>
        </Form>
    );
}