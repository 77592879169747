import { Button } from "antd";
import TileCol from "../tile-col/TileCol";
import classNames from "classnames";
import { PlusOutlined } from "@ant-design/icons";
import "./style.css"

export default function TileRow(props) {
    const {
        cols,
        editable,
        onChange,
    } = props;

    function handleColumnAdd() {
        const newCol = {
            span: 2,
            offset: 0,
        };
        const newCols = [...cols ?? [], newCol];
        onChange({ cols: newCols });
    }

    function handleColumnChange(index, value) {
        const newCols = [...cols ?? []]
            .map((item, i) => i === index ? value : item);
        onChange({ cols: newCols });
    }

    function handleColumnRemove(index) {
        const newCols = [...cols ?? []]
            .filter((_, i) => i !== index);
        onChange({ cols: newCols });
    }

    return (
        <div
            className={classNames({
                'tile-row-container': true,
                'tile-row-editable': editable,
            })}
        >
            {editable && (
                <div className="tile-row-toolbelt">
                    <Button
                        onClick={() => handleColumnAdd()}
                        icon={<PlusOutlined />}
                    />
                </div>
            )}
            <div className="tile-row-content">
                {[...cols ?? []].map((col, index) =>
                    <TileCol
                        span={col.span}
                        offset={col.offset}
                        componentType={col.componentType}
                        componentProps={col.componentProps}
                        editable={editable}
                        onChange={value => handleColumnChange(index, value)}
                        onRemove={() => handleColumnRemove(index)}
                        key={index}
                    />
                )}
            </div>
        </div>
    );
}