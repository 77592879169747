import { useState } from "react";
import { Form, List, Modal } from "antd";
import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import ApartmentMetadataTypeForm from "organizations/forms/ApartmentMetadataTypeForm";

const UPDATE_MUTATION = gql`
    mutation UpdateApartmentMetadataType($input: UpdateApartmentMetadataTypeInput!) {
        updateApartmentMetadataType(input: $input) {
            error {
                type
                message
            }
            apartmentMetadataType {
                id
                name
                nameTranslations
                typeConfig
            }
        }
    }
`;

const DELETE_MUTATION = gql`
    mutation DeleteApartmentMetadataType($input: DeleteApartmentMetadataTypeInput!) {
        deleteApartmentMetadataType(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        apartmentMetadataType,
        organizationId,
    } = props;

    const [updateModalOpen, setUpdateModalOpen] = useState(false);
    const [updateForm] = Form.useForm();

    const [updateApartmentMetadataType, { loading: updateLoading }] = useMutation(UPDATE_MUTATION);
    const [deleteApartmentMetadataType, { loading: deleteLoading }] = useMutation(DELETE_MUTATION, {
        update(cache) {
            cache.evict({
                id: cache.identify({
                    __typename: 'Organization',
                    id: organizationId,
                }),
                fieldName: 'apartmentMetadataTypes',
            });
        }
    });

    function handleUpdate() {
        updateForm
            .validateFields()
            .then(values => {
                updateApartmentMetadataType({
                    variables: {
                        input: {
                            apartmentMetadataTypeId: apartmentMetadataType.id,
                            name: values.name,
                            nameTranslations: values.nameTranslations,
                            typeConfig: values.typeConfig,
                        },
                    },
                })
                    .then(() => {
                        setUpdateModalOpen(false);
                    });
            });
    }

    function handleDelete() {
        Modal.confirm({
            title: 'Do you want to delete this apartment metadata type?',
            okText: 'Delete',
            okButtonProps: {
                danger: true,
                icon: <DeleteOutlined />,
                loading: deleteLoading,
            },
            onOk: () => {
                deleteApartmentMetadataType({
                    variables: {
                        input: {
                            apartmentMetadataTypeId: apartmentMetadataType.id,
                        },
                    },
                });
            },
        })
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setUpdateModalOpen(true)}
                />,
                <DeleteOutlined
                    onClick={() => handleDelete()}
                />
            ]}
        >
            <List.Item.Meta
                title={apartmentMetadataType.name}
            />
            <Modal
                title="Update apartment metadata type"
                width={800}
                open={updateModalOpen}
                destroyOnClose
                onCancel={() => setUpdateModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
            >
                <ApartmentMetadataTypeForm
                    form={updateForm}
                    apartmentMetadataType={apartmentMetadataType}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 18,
                    }}
                />
            </Modal>
        </List.Item>
    );
}