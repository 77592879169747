import { Card, Empty } from "antd";
import { renderVariable } from "stayql/common";
import "./style.css";

export default function StayqlCard(props) {
    const {
        columns,
        rows,
        title,
    } = props;

    const row = rows?.[0];

    const columnDefinitions = [...columns ?? []]
        .map(column => ({
            label: column.name,
            render: value => renderVariable(column.type, value),
        }));

    if (columnDefinitions) {
        return (
            <Card title={title}>
                {row && columnDefinitions.map((column, index) => (
                    <div
                        className="stayql-card-row"
                        key={index}
                    >
                        <div className="stayql-card-label">
                            {column.label}
                        </div>
                        <div className="stayql-card-value">
                            {column.render(row?.values?.[index])}
                        </div>
                    </div>
                ))}
                {!row && (
                    <Empty />
                )}
            </Card>
        );
    }
}