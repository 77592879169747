import { Form, InputNumber } from "antd";
import GuestPricingInput from "apartments/components/guest-pricing-input/GuestPricingInput";
import { useAuth } from "auth";
import CurrencySelect from "components/CurrencySelect";
import InputDefaultWrapper from "components/InputDefaultWrapper";
import { useEffect } from "react";

export default function ApartmentOnboardingPricingForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    const { user } = useAuth();

    useEffect(() => {
        form?.setFieldValue('enableAdditionalGuestFee', apartment?.increasePriceAbove && apartment?.increasePriceBy);

        if (apartment?.cleaningPrice) {
            form?.setFieldValue('cleaningPrice', apartment.cleaningPrice);
        }
        if (apartment?.occupancyPriceModifiers) {
            form?.setFieldValue('occupancyPriceModifiers', apartment.occupancyPriceModifiers);
        }
        else {
            const defaultValue = [...Array(apartment.maxGuestCount).keys()]
                .map(i => i + 1)
                .map(guestCount => ({
                    occupancy: guestCount,
                    mode: 'value',
                    value: 0,
                }))
            form?.setFieldValue('occupancyPriceModifiers', defaultValue);
        }
        if (apartment?.currency) {
            form?.setFieldValue('currency', apartment.currency);
        }
        if (apartment?.defaultPrice) {
            form?.setFieldValue('defaultPrice', apartment.defaultPrice);
        }
    }, [form, apartment]);

    const currency = Form.useWatch('currency', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="currency"
                label="Currency"
                rules={[{ required: true, message: 'Currency is required' }]}
            >
                <InputDefaultWrapper
                    defaultValue={user?.organization?.currency}
                    disabled={apartment?.currency}
                    description="From organization defaults"
                >
                    <CurrencySelect
                        searchable
                    />
                </InputDefaultWrapper>
            </Form.Item>
            <Form.Item
                name="defaultPrice"
                label="Default price"
                rules={[{ required: true, message: 'Default price is required' }]}
            >
                <InputDefaultWrapper
                    defaultValue={user?.organization?.defaultApartmentDefaultPrice}
                    renderValue={value => `${value} ${currency}`}
                    disabled={apartment?.defaultPrice}
                    description="From organization defaults"
                >
                    <InputNumber
                        min={0}
                        addonAfter={currency}
                    />
                </InputDefaultWrapper>
            </Form.Item>
            <Form.Item
                name="occupancyPriceModifiers"
                label="Extra guest price"
                rules={[{
                    validator(_, value) {
                        if (value?.length !== apartment?.maxGuestCount) {
                            return Promise.reject(new Error('Invalid guest count'));
                        }
                        if (value.some(item => item.occupancy === undefined || item.mode === undefined || item.value === undefined)) {
                            return Promise.reject(new Error('You have to specify all values'));
                        }
                        return Promise.resolve();
                    },
                }]}
            >
                <GuestPricingInput
                    maxGuestCount={apartment?.maxGuestCount}
                    currency={currency}
                />
            </Form.Item>
            <Form.Item
                name="cleaningPrice"
                label="Cleaning price"
                rules={[{ required: true, message: 'Cleaning price is required' }]}
            >
                <InputNumber
                    min={0}
                    addonAfter={currency}
                />
            </Form.Item>
        </Form>
    )
}