import { DeleteOutlined, EditOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Form, List, message, Modal, Popconfirm } from "antd";
import ChecklistItemForm from "checklists/forms/ChecklistItemForm";
import { useState } from "react";

const FORM_LAYOUT = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    }
};

const MUTATION_UPDATE = gql`
    mutation UpdateChecklistItem($input: UpdateChecklistItemInput!) {
        updateChecklistItem(input: $input) {
            error {
                type
                message
            }
            checklistItem {
                id
                name
                nameTranslations
                typeConfig
            }
        }
    }
`;

const MUTATION_DELETE = gql`
    mutation DeleteChecklistItem($input: DeleteChecklistItemInput!) {
        deleteChecklistItem(input: $input) {
            error {
                type
                message
            }
            checklist {
                id
                items {
                    id
                }
            }
        }
    }
`;

export default function ListItem(props) {
    const {
        checklistItem,
    } = props;

    const [modalOpen, setModalOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [updateChecklistItem] = useMutation(MUTATION_UPDATE);
    const [deleteChecklistItem] = useMutation(MUTATION_DELETE);

    const [form] = Form.useForm();

    function handleUpdate() {
        form
            .validateFields()
            .then(values => {
                setLoading(true);
                updateChecklistItem({
                    variables: {
                        input: {
                            checklistItemId: checklistItem.id,
                            name: values.name,
                            nameTranslations: values.nameTranslations,
                            typeConfig: values.typeConfig,
                        },
                    },
                })
                    .then(response => {
                        if (response?.data?.updateChecklistItem?.error) {
                            message.error("Failed to update checklist item");
                        }
                        else {
                            message.success("Checklist item updated");
                            setModalOpen(false);
                        }
                        setLoading(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setLoading(false);
                    });
            });
    }

    function handleDelete() {
        deleteChecklistItem({
            variables: {
                input: {
                    checklistItemId: checklistItem.id,
                },
            },
        })
            .then(response => {
                if (response?.data?.deleteChecklistItem?.error) {
                    message.error("Failed to delete item");
                }
                else {
                    message.error("Item deleted");
                }
            })
            .catch(() => {
                message.error("Network error");
            });
    }

    return (
        <List.Item
            actions={[
                <EditOutlined
                    onClick={() => setModalOpen(true)}
                    key="update"
                />,
                <Popconfirm
                    title="Are you sure you want to delete this item?"
                    okText="Delete"
                    onConfirm={() => handleDelete()}
                    key="delete"
                >
                    <DeleteOutlined />
                </Popconfirm>
            ]}
        >
            <List.Item.Meta
                title={checklistItem.name}
            />
            <Modal
                title="Update field"
                open={modalOpen}
                onCancel={() => setModalOpen(false)}
                onOk={() => handleUpdate()}
                okText="Update"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading,
                }}
                width={700}
                destroyOnClose
            >
                <ChecklistItemForm
                    form={form}
                    checklistItem={checklistItem}
                    preserve={false}
                    {...FORM_LAYOUT}
                />
            </Modal>
        </List.Item>
    );
}