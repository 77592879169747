import { Form, InputNumber } from "antd";
import { useEffect } from "react";

export default function ApartmentOccupancySettingsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    useEffect(() => {
        if (apartment?.maxGuestCount) {
            form?.setFieldValue('maxGuestCount', apartment?.maxGuestCount);
        }
    }, [form, apartment]);

    const maxGuestCount = Form.useWatch('maxGuestCount', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="maxGuestCount"
                label="Maximum occupancy"
                rules={[{ required: true, message: 'Maximum occupancy is required' }]}
            >
                <InputNumber
                    addonAfter={maxGuestCount === 1 ? 'guest' : 'guests'}
                />
            </Form.Item>
        </Form>
    );
}