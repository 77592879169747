import { gql, useQuery } from "@apollo/client";
import { Select, Tooltip } from "antd";

const QUERY = gql`
    query GetStayqlQueriesForQuerySelect {
        stayqlQueries {
            id
            name
        }
    }
`;

export default function StayqlQuerySelect(props) {
    const {
        value,
        onChange,
    } = props;

    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Select loading />
        );
    }

    if (error) {
        return (
            <Tooltip title="Failed to load queries">
                <Select disabled />
            </Tooltip>
        );
    }

    return (
        <Select
            value={value}
            onChange={value => onChange(value)}
            options={
                [...data?.stayqlQueries ?? []]
                    .map(query => ({
                        label: query.name,
                        value: query.id,
                    }))
            }
            style={{
                width: '100%',
            }}
        />
    );
}