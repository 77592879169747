import { gql, useLazyQuery } from "@apollo/client";
import { Alert, Button, Col, Input, Row, Typography } from "antd";
import { useEffect, useState } from "react";

const QUERY = gql`
    query GetGeocodindResultsForCreateApartment($query: String!) {
        geocoding(query: $query) {
            id
            label
            latitude
            longitude
        }
    }
`;

export default function ApartmentLocationPicker(props) {
    const {
        value,
        onChange,
        initialAddress,
    } = props;

    const [query, setQuery] = useState(value ? value.address : initialAddress);
    const [geocodingResults, setGeocodingResults] = useState();

    const [getGeocoding, { loading, error }] = useLazyQuery(QUERY);

    useEffect(() => {
        if (initialAddress && !value) {
            getGeocoding({ variables: { query: initialAddress } })
                .then(response => {
                    setGeocodingResults(response?.data?.geocoding ?? []);
                    if (response?.data?.geocoding?.length === 1) {
                        onChange({
                            address: response.data.geocoding[0].label,
                            latitude: response.data.geocoding[0].latitude,
                            longitude: response.data.geocoding[0].longitude,
                        });
                    }
                });
        }
    }, []);

    function handleChangeAddress() {
        onChange({ address: value?.address });
        setQuery(value?.address);
        setGeocodingResults();
    }

    function handleChooseAddress(address) {
        onChange({
            address: address.label,
            latitude: address.latitude,
            longitude: address.longitude,
        });
        setGeocodingResults();
    }

    function handleSearch() {
        getGeocoding({ variables: { query } })
            .then(response => {
                setGeocodingResults(response?.data?.geocoding ?? []);
                if (response?.data?.geocoding?.length === 1) {
                    onChange({
                        address: response.data.geocoding[0].label,
                        latitude: response.data.geocoding[0].latitude,
                        longitude: response.data.geocoding[0].longitude,
                    });
                }
            });
    }

    return (
        <Row gutter={[16, 16]}>
            {!value?.latitude && !value?.longitude && (
                <>
                    <Col span={24}>
                        <Input.Search
                            value={query}
                            onChange={e => setQuery(e.target.value)}
                            onSearch={() => handleSearch()}
                            loading={loading}
                            disabled={loading}
                            placeholder="Address"
                        />
                    </Col>
                    {error && (
                        <Col span={24}>
                            <Alert
                                type="error"
                                showIcon
                                message="Failed to load geocoding data"
                            />
                        </Col>
                    )}
                    {geocodingResults?.length === 0 && (
                        <Col span={24}>
                            <Alert
                                type="info"
                                showIcon
                                message="No address found for given query"
                            />
                        </Col>
                    )}
                    {geocodingResults?.map(item => (
                        <Col
                            span={24}
                            key={item.id}
                        >
                            <Row
                                gutter={[16, 16]}
                                justify="space-between"
                            >
                                <Col>
                                    <Typography.Text>
                                        {item.label}
                                    </Typography.Text>
                                </Col>
                                <Col>
                                    <Button
                                        type="link"
                                        size="small"
                                        onClick={() => handleChooseAddress(item)}
                                    >
                                        Choose
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    ))}
                </>
            )}
            {value?.latitude && value?.longitude && (
                <Col span={24}>
                    <Row
                        gutter={[16, 16]}
                        justify="space-between"
                        align="middle"
                        wrap={false}
                    >
                        <Col>
                            <Typography.Text>
                                {value?.address}
                            </Typography.Text>
                        </Col>
                        <Col>
                            <Button
                                type="link"
                                size="small"
                                onClick={() => handleChangeAddress()}
                            >
                                Change
                            </Button>
                        </Col>
                    </Row>
                </Col>
            )}
        </Row>
    )
}