import { Form } from "antd";
import DatePicker from "components/DatePicker";

export default function ApartmentOpenCalendarForm(props) {
    const {
        form,
        ...otherProps
    } = props;

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="availableFrom"
                label="Available from"
                rules={[{ required: true, message: 'Available from is required' }]}
            >
                <DatePicker
                    allowClear={false}
                />
            </Form.Item>
        </Form>
    );
}