import { Select } from "antd";
import { USER_LANGUAGES } from "users/common";

export default function UserLanguageSelect(props) {
    const {
        value,
        onChange,
        ...otherProps
    } = props;

    return (
        <Select
            options={
                USER_LANGUAGES
                    .map(item => ({
                        value: item.language,
                        label: item.label,
                    }))
            }
            value={value}
            onChange={value => onChange(value)}
            style={{
                width: '100%',
            }}
            {...otherProps}
        />
    );
}