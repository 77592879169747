import classNames from "classnames";
import "./style.css";
import TileRow from "../tile-row/TileRow";
import StayqlWidget from "stayql/components/stayql-widged/StayqlWidget";
import { Button, Form, Modal } from "antd";
import { DeleteOutlined, SaveOutlined, SettingOutlined } from "@ant-design/icons";
import { useContext, useState } from "react";
import TileColSettingsForm from "tiles/forms/TileColSettingsForm";
import { TileContext } from "../tile-context/TileContext";
import ApartmentCalendar from "apartments/components/apartment-calendar/ApartmentCalendar";

export default function TileCol(props) {
    const {
        span,
        offset,
        componentType,
        componentProps,
        editable,
        onChange,
        onRemove,
    } = props;

    const [settingsModalOpen, setSettingsModalOpen] = useState(false);
    const [form] = Form.useForm();
    const contextData = useContext(TileContext);

    function handleSettingsSave() {
        form
            .validateFields()
            .then(values => {
                onChange(values);
                setSettingsModalOpen(false);
            });
    }

    function handleChildrenOnChange(value) {
        onChange({
            span,
            offset,
            componentType,
            componentProps: value,
        })
    }

    return (
        <div
            className={classNames({
                'tile-col-container': true,
                'tile-col-editable': editable,
                'tile-col-span-1': span === 1,
                'tile-col-span-2': span === 2,
                'tile-col-span-3': span === 3,
                'tile-col-span-4': span === 4,
                'tile-col-offset-1': offset === 1,
                'tile-col-offset-2': offset === 2,
                'tile-col-offset-3': offset === 3,
                'tile-col-offset-4': offset === 4,
            })}
        >
            {editable && (
                <div className="tile-col-toolbelt">
                    <Button
                        onClick={() => setSettingsModalOpen(true)}
                        icon={<SettingOutlined />}
                    />
                    <Button
                        onClick={() => onRemove()}
                        icon={<DeleteOutlined />}
                    />
                </div>
            )}
            <div className="tile-col-content">
                {componentType === 'row' && (
                    <TileRow
                        {...componentProps}
                        editable={editable}
                        onChange={value => handleChildrenOnChange(value)}
                    />
                )}
                {componentType === 'stayql' && (
                    <StayqlWidget
                        {...componentProps}
                        variables={contextData?.variables ?? []}
                        editable={editable}
                        onChange={value => handleChildrenOnChange(value)}
                    />
                )}
                {componentType === 'apartmentCalendar' && (
                    <ApartmentCalendar
                        {...componentProps}
                        apartmentId={contextData?.apartmentId}
                        editable={editable}
                        onChange={value => handleChildrenOnChange(value)}
                    />
                )}
                {!componentType && (
                    <div className="tile-col-component-empty" />
                )}
            </div>
            <Modal
                open={settingsModalOpen}
                title="Settings"
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                }}
                onOk={() => handleSettingsSave()}
                onCancel={() => setSettingsModalOpen(false)}
                destroyOnClose
            >
                <TileColSettingsForm
                    form={form}
                    tileCol={{
                        span,
                        offset,
                        componentType,
                        componentProps,
                    }}
                    labelCol={{
                        span: 6,
                    }}
                    wrapperCol={{
                        span: 12,
                    }}
                />
            </Modal>
        </div>
    );
}