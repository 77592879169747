import { formatISO } from "date-fns";

export const STAYQL_TYPE_INTEGER = "integer";
export const STAYQL_TYPE_FLOAT = "float";
export const STAYQL_TYPE_BOOLEAN = "boolean";
export const STAYQL_TYPE_STRING = "string";
export const STAYQL_TYPE_DATE = "date";
export const STAYQL_TYPE_DATETIME = "datetime";
export const STAYQL_TYPE_INTERVAL = "interval";
export const STAYQL_TYPE_ARRAY = "array";

export function variableToString(type, value) {
    if (value === undefined) {
        return null;
    }

    if (type === 'integer') {
        return value.toString();
    }
    if (type === 'float') {
        return value.toString();
    }
    if (type === 'boolean') {
        return value ? 'true' : 'false';
    }
    if (type === 'string') {
        return value;
    }
    if (type === 'date') {
        return formatISO(value, { representation: 'date' });
    }
    if (type === 'datetime') {
        return formatISO(value);
    }
    if (type === 'interval') {
        return value;
    }
    if (type === 'array') {
        return value;
    }
}

export function renderVariable(type, value) {
    if (type === 'integer') {
        if (value !== null && value !== undefined) {
            return parseInt(value).toString();
        }
        return '';
    }
    if (type === 'float') {
        if (value !== null && value !== undefined) {
            return parseFloat(value).toLocaleString();
        }
        return '';
    }
    if (type === 'boolean') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
    if (type === 'string') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
    if (type === 'date') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
    if (type === 'datetime') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
    if (type === 'interval') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
    if (type === 'array') {
        if (value !== null && value !== undefined) {
            return value;
        }
        return '';
    }
}