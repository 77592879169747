import { green, grey } from "@ant-design/colors";
import { CheckCircleFilled, CloseCircleFilled, DeleteOutlined, DisconnectOutlined, EllipsisOutlined, LinkOutlined, LoadingOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Dropdown, List, message } from "antd";
import { cache } from "apollo";
import { taskPromise } from "common/task";
import { useState } from "react";


const CONFIG = {
    BookingCom: {
        label: 'Booking.com',
        params: [
            {
                key: 'hotel_id',
                label: 'Hotel ID',
            },
        ],
    },
    AirBNB: {
        label: 'Airbnb.com',
        params: [
            {
                key: 'listing_id',
                label: 'Listing ID',
            },
        ],
    },
}

const MUTATION_CONNECT = gql`
    mutation ConnectApartmentChannel($input: ConnectApartmentChannelInput!) {
        connectApartmentChannel(input: $input) {
            error {
                type
                message
            }
            connectApartmentChannelTask {
                id
            }
        }
    }
`;

const MUTATION_DISCONNECT = gql`
    mutation DisconnectApartmentChannel($input: DisconnectApartmentChannelInput!) {
        disconnectApartmentChannel(input: $input) {
            error {
                type
                message
            }
            disconnectApartmentChannelTask {
                id
            }
        }
    }
`;

const MUTATION_DELETE = gql`
    mutation DeleteApartmentChannel($input: DeleteApartmentChannelInput!) {
        deleteApartmentChannel(input: $input) {
            error {
                type
                message
            }
            deleteApartmentChannelTask {
                id
            }
        }
    }
`;


export default function ListItem(props) {
    const {
        apartmentId,
        channel,
        editable,
    } = props;

    const [loading, setLoading] = useState(false);
    const [connectApartmentChannel] = useMutation(MUTATION_CONNECT);
    const [disconnectApartmentChannel] = useMutation(MUTATION_DISCONNECT);
    const [deleteApartmentChannel] = useMutation(MUTATION_DELETE);

    function handleAction(action) {
        if (action === 'connect') {
            return handleConnectChannel();
        }
        if (action === 'disconnect') {
            return handleDisconnectChannel();
        }
        if (action === 'delete') {
            return handleDeleteChannel();
        }
    }

    function handleConnectChannel() {
        setLoading(true);
        connectApartmentChannel({
            variables: {
                input: {
                    apartmentChannelId: channel.id,
                },
            },
        })
            .then(response => {
                if (response?.data?.connecApartmentChannelt?.error) {
                    message.error("Failed connecting channel");
                    setLoading(false);
                }
                else {
                    taskPromise(response?.data?.connectApartmentChannel?.connectApartmentChannelTask?.id)
                        .then(() => {
                            message.success("Channel connected");
                            setLoading(false);
                            cache.evict({
                                id: cache.identify({
                                    __typename: 'ApartmentChannel',
                                    id: channel.id,
                                }),
                                fieldName: 'active',
                            });
                        })
                        .catch(() => {
                            message.error("Failed connecting channel");
                            setLoading(false);
                        });
                }
            })
            .catch(() => {
                message.error("Network error");
                setLoading(false);
            });
    }

    function handleDisconnectChannel() {
        setLoading(true);
        disconnectApartmentChannel({
            variables: {
                input: {
                    apartmentChannelId: channel.id,
                },
            },
        })
            .then(response => {
                if (response?.data?.disconnectApartmentChannel?.error) {
                    message.error("Failed disconnecting channel");
                    setLoading(false);
                }
                else {
                    taskPromise(response?.data?.disconnectApartmentChannel?.disconnectApartmentChannelTask?.id)
                        .then(() => {
                            message.success("Channel disconnected");
                            setLoading(false);
                            cache.evict({
                                id: cache.identify({
                                    __typename: 'ApartmentChannel',
                                    id: channel.id,
                                }),
                                fieldName: 'active',
                            });
                        })
                        .catch(() => {
                            message.error("Failed discconnecting channel");
                            setLoading(false);
                        });
                }
            })
            .catch(() => {
                message.error("Network error");
                setLoading(false);
            });
    }

    function handleDeleteChannel() {
        setLoading(true);
        deleteApartmentChannel({
            variables: {
                input: {
                    apartmentChannelId: channel.id,
                },
            },
        })
            .then(response => {
                if (response?.data?.deleteApartmentChannel?.error) {
                    message.error("Failed deleting channel");
                    setLoading(false);
                }
                else {
                    taskPromise(response?.data?.deleteApartmentChannel?.deleteApartmentChannelTask?.id)
                        .then(() => {
                            message.success("Channel deleted");
                            setLoading(false);
                            cache.evict({
                                id: cache.identify({
                                    __typename: 'Apartment',
                                    id: apartmentId,
                                }),
                                fieldName: 'channels',
                            });
                        })
                        .catch(() => {
                            message.error("Failed deleting channel");
                            setLoading(false);
                        });
                }
            })
            .catch(() => {
                message.error("Network error");
                setLoading(false);
            });
    }

    function getAvatar() {
        if (loading) {
            return (
                <LoadingOutlined
                    style={{
                        fontSize: '24px',
                    }}
                />
            );
        }
        else {
            if (channel.active) {
                return (
                    <CheckCircleFilled
                        style={{
                            color: green.primary,
                            fontSize: '24px',
                        }}
                    />
                );
            }
            else {
                return (
                    <CloseCircleFilled
                        style={{
                            color: grey.primary,
                            fontSize: '24px',
                        }}
                    />
                );
            }
        }
    }

    const avatar = getAvatar();

    const title = CONFIG[channel.otaName].label;
    const description = CONFIG[channel.otaName].params
        .map(param => `${param.label}: ${channel?.config?.settings?.[param.key]}`)
        .join(', ');

    const actions = [];
    if (editable) {
        if (channel.active) {
            actions.push({
                label: 'Disconnect',
                key: 'disconnect',
                icon: <DisconnectOutlined />
            });
        }
        else {
            actions.push({
                label: 'Connect',
                key: 'connect',
                icon: <LinkOutlined />,
            });
            actions.push({
                label: 'Delete',
                key: 'delete',
                icon: <DeleteOutlined />,
            });
        }
    }

    return (
        <List.Item
            actions={[
                <Dropdown
                    menu={{
                        items: actions,
                        onClick: ({ key }) => handleAction(key),
                    }}
                    disabled={loading}
                >
                    <EllipsisOutlined />
                </Dropdown>
            ]}
        >
            <List.Item.Meta
                avatar={avatar}
                title={title}
                description={description}
            />
        </List.Item>
    );
}