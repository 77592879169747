import { Button, Col, Row } from "antd";
import { useState } from "react";
import ApartmentOnboardingChannel from "../apartment-onboarding-channel/ApartmentOnboardingChannel";
import { PlusOutlined } from "@ant-design/icons";
import ApartmentChannelsList from "../apartment-channels-list/ApartmentChannelsList";
import { cache } from "apollo";

export default function ApartmentOnboardingChannels(props) {
    const {
        apartmentId,
        onConnected,
    } = props;

    const [isAddingChannel, setIsAddingChannel] = useState(false);

    function handleOnCreate() {
        cache.evict({
            id: cache.identify({
                __typename: 'Apartment',
                id: apartmentId,
            }),
            fieldName: 'channels',
        });
        setIsAddingChannel(false);
        onConnected();
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <ApartmentChannelsList
                    apartmentId={apartmentId}
                    editable
                />
            </Col>
            {isAddingChannel && (
                <Col span={24}>
                    <ApartmentOnboardingChannel
                        apartmentId={apartmentId}
                        onCreate={() => handleOnCreate()}
                        onDelete={() => setIsAddingChannel(false)}
                    />
                </Col>
            )}
            {!isAddingChannel && (
                <Col span={24}>
                    <Button
                        type="dashed"
                        icon={<PlusOutlined />}
                        block
                        onClick={() => setIsAddingChannel(true)}
                    >
                        Add channel
                    </Button>
                </Col>
            )}
        </Row>
    );
}