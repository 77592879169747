import { gql, useQuery } from "@apollo/client";
import { Alert, PageHeader, Skeleton } from "antd";
import { useAuth } from "auth";
import { useNavigate, useParams } from "react-router-dom";
import { STAYQL_TYPE_INTEGER } from "stayql/common";
import { TileContext } from "tiles/components/tile-context/TileContext";
import TileRow from "tiles/components/tile-row/TileRow";

const QUERY = gql`
    query GetApartmentForShowApartmentView($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            tags
        }
    }
`;

export default function ShowApartmentView() {
    const { apartmentId } = useParams();
    const navigate = useNavigate();

    const { user } = useAuth();
    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    const apartmentTiles = user?.organization?.apartmentTiles;

    return (
        <PageHeader
            title={data?.apartment?.name}
            onBack={() => navigate(-1)}
        >
            {loading && (
                <Skeleton />
            )}
            {error && (
                <Alert
                    type="error"
                    showIcon
                    message="Failed to load apartment"
                />
            )}
            {!loading && data && !apartmentTiles && (
                <Alert
                    type="error"
                    showIcon
                    message="Apartment tiles are not configured for you"
                />
            )}
            {!loading && data && apartmentTiles && (
                <TileContext.Provider
                    value={{
                        apartmentId,
                        variables: [
                            {
                                name: 'apartment_id',
                                type: STAYQL_TYPE_INTEGER,
                                value: apartmentId,
                            },
                        ],
                    }}
                >
                    <TileRow cols={apartmentTiles.cols} />
                </TileContext.Provider>
            )}
        </PageHeader>
    );
}