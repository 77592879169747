import { createContext, useContext, useState } from 'react';
import { gql, useMutation, useQuery } from '@apollo/client';
import { formatISO, isPast, parseISO, subDays } from 'date-fns';

const AUTH_TOKEN_KEY = 'authToken';
const AUTH_TOKEN_VALID_TO_KEY = 'authTokenValidTo';

const QUERY = gql`
    query GetUserSelf {
        user(userId: "self") {
            id
            firstName
            lastName
            email
            phone
            role
            permissions
            status
            config
            language
            webMenu
            organization {
                id
                name
                config
                timezone
                currency
                apartmentTags
                jobTags
                reservationTags
                apartmentTiles
                defaultApartmentCalendarLength
                defaultApartmentCutOffTime
                defaultApartmentDefaultPrice
                defaultPriceConfigDefaultMinPrice
                defaultPriceConfigDefaultDelta
                defaultPriceConfigDefaultSlope
                userGroups {
                    id
                    name
                }
            }
            groups {
                id
                webMenu
                permissions
            }
        }
    }
`;

const LOGIN_MUTATION = gql`
    mutation Login($input: LoginInput!) {
        login(input: $input) {
            error {
                type
                message
            }
            token
            validTo
        }
    }
`;

const LOGOUT_MUTATION = gql`
    mutation Logout {
        logout {
            error {
                type
                message
            }
        }
    }
`;

const REFRESH_AUTH_TOKEN_MUTATION = gql`
    mutation RefreshAuthToken {
        refreshAuthToken {
            error {
                type
                message
            }
            validTo
        }
    }
`;

const AuthContext = createContext();

export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({ children }) {
    const validToStr = window.localStorage.getItem(AUTH_TOKEN_VALID_TO_KEY);
    const validTo = validToStr ? parseISO(validToStr) : undefined;
    const skipGetUser = !validTo || isPast(validTo);

    const [ready, setReady] = useState(skipGetUser);
    const [loading, setLoading] = useState(!skipGetUser);
    const [error, setError] = useState();

    const { data, refetch } = useQuery(QUERY, {
        onCompleted() {
            setReady(true);
            setLoading(false);
        },
        skip: skipGetUser,
        fetchPolicy: 'network-only',
    });

    const permissions = [
        ...[...data?.user?.permissions ?? []],
        ...[...data?.user?.groups ?? []].map(group => group.permissions).flat()
    ]
        .filter((value, index, array) => array.indexOf(value) === index);

    const userGroupIds = [...data?.user?.groups ?? []]
        .map(group => group.id);

    const [login] = useMutation(LOGIN_MUTATION);
    const [logout] = useMutation(LOGOUT_MUTATION);
    const [refreshAuthToken] = useMutation(REFRESH_AUTH_TOKEN_MUTATION);

    function handleLogin({ email, phone, password }) {
        setLoading(true);
        return login({
            variables: {
                input: {
                    email,
                    phone,
                    password,
                },
            },
        })
            .then(response => {
                if (!response.data.login.error) {
                    const token = response.data.login.token;
                    const validTo = response.data.login.validTo;
                    const validToStr = formatISO(validTo);
                    window.localStorage.setItem(AUTH_TOKEN_KEY, token);
                    window.localStorage.setItem(AUTH_TOKEN_VALID_TO_KEY, validToStr);

                    refetch();
                }
                else {
                    setError(response.data.login.error);
                }
                setLoading(false);
            })
            .catch(() => {
                setError("Network error");
                setLoading(false);
            });
    }

    function handleLogout() {
        setLoading(true);
        return logout()
            .then(response => {
                if (!response.data.logout.error) {
                    window.localStorage.removeItem(AUTH_TOKEN_KEY);
                    window.localStorage.removeItem(AUTH_TOKEN_VALID_TO_KEY);

                    refetch();
                }
                else {
                    setError(response.data.logout.error);
                }
                setLoading(false);
            })
            .catch(() => {
                setError("Network error");
                setLoading(false);
            });
    }

    function handleRefresh() {
        refreshAuthToken()
            .then(response => {
                if (response.data.refreshAuthToken) {
                    const validTo = response.data.refreshAuthToken.validTo;
                    const validToStr = formatISO(validTo);
                    window.localStorage.setItem(AUTH_TOKEN_VALID_TO_KEY, validToStr);
                }
                else {
                    setError('Failed to refresh auth token');
                }
            });
    }

    if (validTo && isPast(subDays(validTo, 7))) {
        handleRefresh();
    }

    return (
        <AuthContext.Provider
            value={{
                user: data?.user,
                permissions,
                userGroupIds,
                loading,
                error,
                login: handleLogin,
                logout: handleLogout,
            }}
        >
            {ready && children}
        </AuthContext.Provider>
    );
}

