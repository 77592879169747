import { gql, useQuery } from "@apollo/client";
import { Alert, List, Skeleton } from "antd";
import ListItem from "./ListItem";

const QUERY = gql`
    query GetChecklistItemsForList($checklistId: ID!) {
        checklist(checklistId: $checklistId) {
            id
            items {
                id
                name
                nameTranslations
                order
                typeConfig
            }
        }
    }
`;

export default function ChecklistItemsList(props) {
    const {
        checklistId,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { checklistId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load checklist items"
            />
        );
    }

    const checklistItems = [...data?.checklist?.items ?? []]
        .sort((a, b) => a.order - b.order);

    return (
        <List
            dataSource={checklistItems}
            renderItem={checklistItem => (
                <ListItem
                    checklistItem={checklistItem}
                />
            )}
        />
    )
}