import { CloseOutlined, ThunderboltOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Alert, Button, Col, Input, message, Row, Select } from "antd";
import { cache } from "apollo";
import { taskPromise } from "common/task";
import { useState } from "react";

const CONFIG = [
    {
        otaLabel: 'Booking.com',
        otaName: 'BookingCom',
        fields: [
            {
                name: 'hotel_id',
                label: 'Hotel ID',
                type: 'input',
            },
        ]
    },
    {
        otaLabel: 'Airbnb.com',
        otaName: 'AirBNB',
        fields: [
            {
                name: 'listing_id',
                label: 'Listing ID',
                type: 'input',
            },
        ],
    },
];

const MUTATION = gql`
    mutation MapApartment($input: MapApartmentInput!) {
        mapApartment(input: $input) {
            error {
                type
                message
            }
            mapApartmentTask {
                id
            }
        }
    }
`;

export default function ApartmentOnboardingChannel(props) {
    const {
        apartmentId,
        onCreate,
        onDelete,
    } = props;

    const [otaName, setOtaName] = useState();
    const [settings, setSettings] = useState();
    const [fields, setFields] = useState();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();

    const [mapApartment] = useMutation(MUTATION);

    function handleOtaNamechange(value) {
        setOtaName(value);
        setFields(CONFIG.find(item => item.otaName === value)?.fields ?? []);
    }

    function handleSettingsChange(fieldName, value) {
        setSettings({ ...settings ?? {}, [fieldName]: value });
    }

    async function handleConnect() {
        try {
            setLoading(true);
            const mapApartmentResponse = await mapApartment({
                variables: {
                    input: {
                        apartmentId,
                        otaName,
                        settings,
                    },
                },
            });

            const mapApartmentResult = await taskPromise(mapApartmentResponse?.data?.mapApartment?.mapApartmentTask?.id);

            if (mapApartmentResult.status === 'error') {
                setError(mapApartmentResult.message);
                setLoading(false);
                return;
            }

            if (mapApartmentResult.status === 'user_input_required') {
                // TODO: handle add more information
                return
            }

            if (mapApartmentResult.status !== 'success') {
                setError("Unknown status");
                setLoading(false);
                return;
            }

            message.success("Apartment mapped");
            setLoading(false);

            cache.evict({
                id: cache.identify({
                    __typename: 'Apartment',
                    id: apartmentId,
                }),
                fieldName: 'channels',
            });

            onCreate();
        }
        catch {
            message.error("Failed to map apartment");
            setLoading(false);
        }
    }

    const otaNameOptions = CONFIG.map(item => ({
        label: item.otaLabel,
        value: item.otaName,
    }));

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Row
                    gutter={[16, 16]}
                    align="middle"
                >
                    <Col flex={1}>
                        <Row gutter={[16, 16]}>
                            <Col>
                                <Select
                                    value={otaName}
                                    onChange={value => handleOtaNamechange(value)}
                                    placeholder="OTA Name"
                                    options={otaNameOptions}
                                    disabled={loading}
                                    style={{
                                        width: '200px',
                                    }}
                                />
                            </Col>
                            {[...fields ?? []].map(field => (
                                <Col key={field.name}>
                                    {field.type === 'input' && (
                                        <Input
                                            value={settings?.[field.name]}
                                            onChange={e => handleSettingsChange(field.name, e.target.value)}
                                            placeholder={field.label}
                                            disabled={loading}
                                        />
                                    )}
                                </Col>
                            ))}
                        </Row>
                    </Col>
                    <Col flex={0}>
                        <Button
                            type="primary"
                            icon={<ThunderboltOutlined />}
                            onClick={() => handleConnect()}
                            loading={loading}
                        >
                            Connect
                        </Button>
                    </Col>
                    <Col flex={0}>
                        <CloseOutlined
                            onClick={() => onDelete()}
                        />
                    </Col>
                </Row>
            </Col>
            {error && (
                <Col span={24}>
                    <Alert
                        type="error"
                        showIcon
                        message={error}
                    />
                </Col>
            )}
        </Row>
    );
}