import { Form, Input, InputNumber, Select } from "antd";
import StayqlQuerySelect from "stayql/components/stayql-query-select/StayqlQuerySelect";
import useDeepCompareEffect from "use-deep-compare-effect";

export default function TileColSettingsForm(props) {
    const {
        form,
        tileCol,
        ...otherProps
    } = props;

    useDeepCompareEffect(() => {
        form?.setFieldsValue({ ...tileCol ?? {} });
    }, [form, tileCol]);

    const componentType = Form.useWatch('componentType', form);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="span"
                label="Span"
                rules={[{ required: true, message: 'Span is required' }]}
            >
                <InputNumber
                    min={1}
                    max={4}
                />
            </Form.Item>
            <Form.Item
                name="offset"
                label="Offset"
                rules={[{ required: true, message: 'Offset is required' }]}
            >
                <InputNumber
                    min={0}
                    max={3}
                />
            </Form.Item>
            <Form.Item
                name="componentType"
                label="Component"
            >
                <Select
                    options={[
                        { label: 'Row', value: 'row' },
                        { label: 'StayQL', value: 'stayql' },
                        { label: 'Apartment calendar', value: 'apartmentCalendar' },
                        { label: 'Apartment external links', value: 'apartmentCalendar' },
                    ]}
                    allowClear
                />
            </Form.Item>
            {componentType === 'stayql' && (
                <>
                    <Form.Item
                        name={['componentProps', 'stayqlQueryId']}
                        label="Query"
                        rules={[{ required: true, message: 'Query is required' }]}
                    >
                        <StayqlQuerySelect />
                    </Form.Item>
                    <Form.Item
                        name={['componentProps', 'type']}
                        label="Type"
                        rules={[{ required: true, message: 'Type is required' }]}
                    >
                        <Select
                            options={[
                                { label: 'Table', value: 'table' },
                                { label: 'Card', value: 'card' },
                                { label: 'Line chart', value: 'lineChart' },
                                { label: 'Bar chart', value: 'barChart' },
                                { label: 'Pie chart', value: 'pieChart' },
                                { label: 'Gauge', value: 'gauge' },
                            ]}
                        />
                    </Form.Item>
                    <Form.Item
                        name={['componentProps', 'title']}
                        label="Title"
                    >
                        <Input />
                    </Form.Item>
                </>
            )}
        </Form>
    );
}