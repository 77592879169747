import { CloseOutlined } from "@ant-design/icons";
import { Button, Col, Input, Row, Select } from "antd";

export default function InputFieldDefinition(props) {
    const {
        value,
        onChange,
    } = props;

    function handleOptionChange(index, option) {
        const newOptions = [...value?.options ?? []];
        newOptions[index] = option;
        onChange({ ...value, options: newOptions });
    }

    function handleAddOption() {
        const newOptions = [...value?.options ?? [], ''];
        onChange({ ...value, options: newOptions });
    }

    function handleOptionRemove(index) {
        const newOptions = [...value?.options ?? []].filter((_, otherIndex) => otherIndex !== index);
        onChange({ ...value, options: newOptions });
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <Select
                    value={value?.type}
                    onChange={value => onChange({ type: value })}
                    options={[
                        {
                            value: 'list',
                            label: 'List',
                        },
                        {
                            value: 'switch',
                            label: 'Switch',
                        },
                        {
                            value: 'date',
                            label: 'Date',
                        }
                    ]}
                    style={{
                        width: '150px',
                    }}
                />
            </Col>
            {value?.type === 'list' && (
                <>
                    {[...value?.options ?? []].map((option, index) => (
                        <Col
                            span={24}
                            key={index}
                        >
                            <Row
                                gutter={[16, 16]}
                                align="middle"
                            >
                                <Col flex={1}>
                                    <Input
                                        value={option}
                                        onChange={e => handleOptionChange(index, e.target.value)}
                                        placeholder="Option"
                                    />
                                </Col>
                                <Col flex={0}>
                                    <CloseOutlined
                                        onClick={() => handleOptionRemove(index)}
                                    />
                                </Col>
                            </Row>
                        </Col>
                    ))}
                    <Col span={24}>
                        <Button
                            block
                            onClick={() => handleAddOption()}
                        >
                            Add option
                        </Button>
                    </Col>
                </>
            )}
        </Row>
    )
}