import { Form } from "antd";
import ApartmentTagsSelect from "apartments/components/apartment-select/ApartmentTagsSelect";
import { useEffect } from "react";

export default function ApartmentTagsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            tags: apartment?.tags ?? [],
        });
    }, [form, apartment]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="tags"
                label="Tags"
                rules={[{ required: true, message: 'Tags are required' }]}
            >
                <ApartmentTagsSelect
                    mode="multiple"
                />
            </Form.Item>
        </Form>
    );
}