import TileRow from "../tile-row/TileRow";

export default function TileDesigner(props) {
    const {
        value,
        onChange,
    } = props;

    return (
        <TileRow
            cols={value?.cols ?? []}
            onChange={value => onChange(value)}
            editable
        />
    );
}