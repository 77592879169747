import { PlusOutlined } from "@ant-design/icons";
import { gql, useMutation } from "@apollo/client";
import { Button, Card, Col, Form, message, PageHeader, Row } from "antd";
import ChecklistForm from "checklists/forms/ChecklistForm";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const FORM_LAYOUT = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 8,
    },
};

const MUTATION = gql`
    mutation CreateChecklist($input: CreateChecklistInput!) {
        createChecklist(input: $input) {
            error {
                type
                message
            }
            checklist {
                id
                name
            }
        }
    }
`;

export default function CreateChecklistView() {
    const navigate = useNavigate();

    const [loading, setLoading] = useState(false);

    const [createChecklist] = useMutation(MUTATION);

    const [form] = Form.useForm();

    function handleCreate() {
        form
            .validateFields()
            .then(values => {
                setLoading(true);
                const input = {
                    name: values.name,
                };
                if (values.apartments.choice === 'connectToAll') {
                    input.allApartments = true;
                }
                if (values.apartments.choice === 'connectToSelected') {
                    input.apartmentIds = values.apartments.value;
                }
                if (values.storages.choice === 'connectToAll') {
                    input.allStorages = true;
                }
                if (values.storages.choice === 'connectToSelected') {
                    input.storageIds = values.storages.value;
                }
                createChecklist({
                    variables: {
                        input,
                    },
                })
                    .then(response => {
                        if (response?.data?.createChecklist?.error) {
                            message.error("Failed to create checklist");
                            setLoading(false);
                        }
                        else {
                            message.success("Checklist created");
                            navigate(`/checklists/${response.data.createChecklist.checklist.id}/edit`);
                        }
                    })
                    .catch(e => {
                        console.log(e);
                        message.error("Network error");
                        setLoading(false);
                    });
            });
    }

    return (
        <PageHeader
            title="Create checklist"
            onBack={() => navigate(-1)}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <ChecklistForm
                            form={form}
                            {...FORM_LAYOUT}
                        />
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    onClick={() => handleCreate()}
                                    icon={<PlusOutlined />}
                                    loading={loading}
                                >
                                    Create
                                </Button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
        </PageHeader>
    )
}