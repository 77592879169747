import { useQuery } from "@apollo/client";
import { Alert, Card } from "antd";
import { gql } from "graphql.macro";
import StayqlTable from "../stayql-table/StayqlTable";
import StayqlCard from "../stayql-card/StayqlCard";

const QUERY = gql`
    query GetStayqlQuery($input: RunStayqlQueryInput!) {
        runStayqlQuery(input: $input) {
            columns {
                name
                type
            }
            rows {
                values
            }
            error {
                message
            }
        }
    }
`;

export default function StayqlWidget(props) {
    const {
        stayqlQueryId,
        variables,
        type,
        title,
    } = props;

    const { data, loading, error } = useQuery(QUERY, {
        variables: {
            input: {
                stayqlQueryId,
                variables: variables ?? [],
            },
        },
    });

    if (loading) {
        return (
            <Card
                title={title}
                loading
            />
        );
    }

    if (error) {
        return (
            <Card title={title}>
                <Alert
                    type="error"
                    showIcon
                    message="Network error"
                />
            </Card>
        );
    }

    if (data?.runStayqlQuery?.error) {
        return (
            <Card title={title}>
                <Alert
                    type="error"
                    showIcon
                    message={data.runStayqlQuery.error.message}
                />
            </Card>
        );
    }

    if (type === 'table') {
        return (
            <StayqlTable
                columns={data.runStayqlQuery.columns}
                rows={data.runStayqlQuery.rows}
                title={title}
            />
        );
    }
    if (type === 'card') {
        return (
            <StayqlCard
                columns={data.runStayqlQuery.columns}
                rows={data.runStayqlQuery.rows}
                title={title}
            />
        );
    }

    return (
        <Card title={title}>
            <Alert
                type="error"
                showIcon
                message="This type of widged is not implemented yet"
            />
        </Card>
    );
}