import { Form, InputNumber } from "antd";
import MinimumStayInput from "apartments/components/minimum-stay-input/MinimumStayInput";
import { useAuth } from "auth";
import InputDefaultWrapper from "components/InputDefaultWrapper";
import RadioGroupInput from "components/radio-group-input/RadioGroupInput";
import TimeSelect from "components/TimeSelect";
import { useEffect } from "react";

export default function ApartmentAvailabilitySettingsForm(props) {
    const {
        form,
        apartment,
        ...otherProps
    } = props;

    const { user } = useAuth();

    useEffect(() => {
        if (apartment?.maxAvailability) {
            form.setFieldValue('unitSize', {
                choice: apartment.maxAvailability === 1 ? 'single' : 'multiple',
                value: apartment.maxAvailability,
            });
        }
        if (apartment?.cutOffTime) {
            form.setFieldValue('cutOffTime', apartment?.cutOffTime);
        }
        if (apartment?.weekdayMinStay) {
            form?.setFieldValue('weekdayMinStay', apartment?.weekdayMinStay);
        }
    }, [form, apartment]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="unitSize"
                label="Unit size"
            >
                <RadioGroupInput
                    options={[
                        {
                            key: 'single',
                            label: 'Single',
                            description: 'This apartment is sold as single unit',
                        },
                        {
                            key: 'multiple',
                            label: 'Multiple',
                            description: 'This apartment is sold as multiple units',
                            input: (
                                <InputNumber
                                    min={2}
                                    addonAfter="units"
                                />
                            ),
                        },
                    ]}
                    defaultOption="single"
                />
            </Form.Item>
            <Form.Item
                name="cutOffTime"
                label="Cut-off time"
                rules={[{ required: true, message: 'Cut-off time is required' }]}
            >
                <InputDefaultWrapper
                    defaultValue={user.organization.defaultApartmentCutOffTime}
                    disabled={apartment?.cutOffTime}
                    description="From organization defaults"
                >
                    <TimeSelect
                        startTime="15:00"
                        endTime="23:30"
                        minutesInterval={30}
                    />
                </InputDefaultWrapper>
            </Form.Item>
            <Form.Item
                name="weekdayMinStay"
                label="Minimum stay"
                rules={[
                    { required: true, message: 'Minimum stay is required' },
                    {
                        validator(_, value) {
                            if (!value.every(minStay => minStay)) {
                                return Promise.reject(new Error('Minimum stay is required'));
                            }
                            return Promise.resolve();
                        }
                    }
                ]}
            >
                <MinimumStayInput />
            </Form.Item>
        </Form>
    );
}