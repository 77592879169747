import { Form, InputNumber } from "antd";
import { useAuth } from "auth";
import TimeSelect from "components/TimeSelect";
import { useEffect } from "react";

export default function OrganizationApartmentDefaultsForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    const { user } = useAuth();

    useEffect(() => {
        form?.setFieldsValue({
            defaultApartmentCalendarLength: organization?.defaultApartmentCalendarLength,
            defaultApartmentCutOffTime: organization?.defaultApartmentCutOffTime,
            defaultApartmentDefaultPrice: organization?.defaultApartmentDefaultPrice,
        });
    }, [form, organization]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="defaultApartmentCalendarLength"
                label="Calendar length"
            >
                <InputNumber
                    min={1}
                    max={720}
                    addonAfter="days"
                />
            </Form.Item>
            <Form.Item
                name="defaultApartmentCutOffTime"
                label="Cut-off time"
            >
                <TimeSelect
                    startTime="10:00"
                    endTime="23:30"
                    minutesInterval={30}
                />
            </Form.Item>
            <Form.Item
                name="defaultApartmentDefaultPrice"
                label="Default price"
            >
                <InputNumber
                    min={1}
                    addonAfter={user?.organization?.currency}
                />
            </Form.Item>
        </Form>
    );
}