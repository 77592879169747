import { Alert, List, Skeleton } from "antd";
import ListItem from "./ListItem";
import { gql, useQuery } from "@apollo/client";

const QUERY = gql`
    query GetOrganizationTiles {
        organization(organizationId: "self") {
            id
            apartmentTiles
            dashboardTiles
        }
    }
`;

export default function OrganizationTiles() {
    const { data, loading, error } = useQuery(QUERY);

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load organization tiles"
            />
        );
    }

    return (
        <List>
            <ListItem
                label="Apartment"
                tiles={data.organization.apartmentTiles}
                organizationId={data.organization.id}
                propName="apartmentTiles"
            />
            <ListItem
                label="Dashboard"
                tiles={data.organization.dashboardTiles}
                organizationId={data.organization.id}
                propName="dashboardTiles"
            />
        </List>
    );
}