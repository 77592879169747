import { EditOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Button, Card, Col, Form, message, Modal, PageHeader, Result, Row, Skeleton } from "antd";
import ChecklistItemsList from "checklists/components/checklist-items-list/ChecklistItemsList";
import ChecklistForm from "checklists/forms/ChecklistForm";
import ChecklistItemForm from "checklists/forms/ChecklistItemForm";
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

const FORM_LAYOUT = {
    labelCol: {
        span: 8,
    },
    wrapperCol: {
        span: 16,
    }
};

const QUERY = gql`
    query GetChecklistForUpdate($checklistId: ID!) {
        checklist(checklistId: $checklistId) {
            id
            name
            allApartments
            apartments {
                id
            }
            allStorages
            storages {
                id
            }
            items {
                id
            }
        }
    }
`;

const MUTATION_CREATE = gql`
    mutation CreateChecklistItem($input: CreateChecklistItemInput!) {
        createChecklistItem(input: $input) {
            error {
                type
                message
            }
            checklist {
                id
                items {
                    id
                }
            }
            checklistItem {
                id
                name
                nameTranslations
                typeConfig
            }
        }
    }
`;

const MUTATION_UPDATE = gql`
    mutation UpdateChecklist($input: UpdateChecklistInput!) {
        updateChecklist(input: $input) {
            error {
                type
                message
            }
            checklist {
                id
                name
                allApartments
                apartments {
                    id
                }
                allStorages
                storages {
                    id
                }
            }
        }
    }
`;

export default function UpdateChecklistView() {
    const { checklistId } = useParams();
    const navigate = useNavigate();

    const [addItemModalOpen, setAddItemModalOpen] = useState(false);
    const [editChecklistModalOpen, setEditChecklistModalOpen] = useState(false);
    const [createLoading, setCreateLoading] = useState(false);
    const [updateLoading, setUpdateLoading] = useState(false);

    const { data, loading, error } = useQuery(QUERY, { variables: { checklistId } });
    const [createChecklistItem] = useMutation(MUTATION_CREATE);
    const [updateChecklist] = useMutation(MUTATION_UPDATE);

    const [createItemForm] = Form.useForm();
    const [editChecklistForm] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to get checklist"
            />
        );
    }

    function handleCreate() {
        createItemForm
            .validateFields()
            .then(values => {
                setCreateLoading(true);
                createChecklistItem({
                    variables: {
                        input: {
                            checklistId,
                            name: values.name,
                            nameTranslations: values.nameTranslations,
                            typeConfig: values.typeConfig,
                        },
                    },
                })
                    .then(response => {
                        if (response?.data?.createChecklistItem?.error) {
                            message.error("Failed to create item");
                        }
                        else {
                            message.success("Item created");
                            setAddItemModalOpen(false);
                        }
                        setCreateLoading(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setCreateLoading(false);
                    });
            });
    }

    function handleUpdateChecklist() {
        editChecklistForm
            .validateFields()
            .then(values => {
                setUpdateLoading(true);
                const input = {
                    checklistId,
                    name: values.name,
                };
                if (values.apartments.choice === 'connectToAll') {
                    input.allApartments = true;
                }
                if (values.apartments.choice === 'connectToSelected') {
                    input.apartmentIds = values.apartments.value;
                }
                if (values.storages.choice === 'connectToAll') {
                    input.allStorages = true;
                }
                if (values.storages.choice === 'connectToSelected') {
                    input.storageIds = values.storages.value;
                }

                updateChecklist({
                    variables: {
                        input,
                    },
                })
                    .then(response => {
                        if (response?.data?.updateChecklist?.error) {
                            message.error("Failed to update checklist");
                        }
                        else {
                            message.success("Checklist updated");
                            setEditChecklistModalOpen(false);
                        }
                        setUpdateLoading(false);
                    })
                    .catch(() => {
                        message.error("Network error");
                        setUpdateLoading(false);
                    });
            })
    }

    return (
        <PageHeader
            title={data?.checklist?.name}
            onBack={() => navigate(-1)}
            extra={[
                <Button
                    type="primary"
                    onClick={() => setEditChecklistModalOpen(true)}
                    icon={<EditOutlined />}
                    key="edit"
                >
                    Edit checklist
                </Button>
            ]}
        >
            <Card>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        {data?.checklist?.items?.length > 0 && (
                            <ChecklistItemsList
                                checklistId={checklistId}
                            />
                        )}
                        {data?.checklist?.items?.length === 0 && (
                            <Result
                                title="No items"
                                subTitle="Add items to this checklist"
                            />
                        )}
                    </Col>
                    <Col span={24}>
                        <Row justify="end">
                            <Col>
                                <Button
                                    type="primary"
                                    icon={<PlusOutlined />}
                                    onClick={() => setAddItemModalOpen(true)}
                                >
                                    Add item
                                </Button>
                                <Modal
                                    title="Add item"
                                    open={addItemModalOpen}
                                    onCancel={() => setAddItemModalOpen(false)}
                                    onOk={() => handleCreate()}
                                    okText="Create"
                                    okButtonProps={{
                                        icon: <PlusOutlined />,
                                        loading: createLoading,
                                    }}
                                    width={700}
                                    destroyOnClose
                                >
                                    <ChecklistItemForm
                                        form={createItemForm}
                                        preserve={false}
                                        {...FORM_LAYOUT}
                                    />
                                </Modal>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Edit checklist"
                open={editChecklistModalOpen}
                onCancel={() => setEditChecklistModalOpen(false)}
                onOk={() => handleUpdateChecklist()}
                okText="Save"
                okButtonProps={{
                    icon: <SaveOutlined />,
                    loading: updateLoading,
                }}
                width={700}
                destroyOnClose
            >
                <ChecklistForm
                    form={editChecklistForm}
                    checklist={data?.checklist}
                    preserve={false}
                    {...FORM_LAYOUT}
                />
            </Modal>
        </PageHeader>
    );
}