import { Form, InputNumber } from "antd";
import PriceConfigSlopeSelect from "prices/PriceConfigSlopeSelect";
import { useEffect } from "react";

export default function OrganizationPriceConfigDefaultsForm(props) {
    const {
        form,
        organization,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            defaultPriceConfigDefaultMinPrice: organization?.defaultPriceConfigDefaultMinPrice,
            defaultPriceConfigDefaultDelta: organization?.defaultPriceConfigDefaultDelta,
            defaultPriceConfigDefaultSlope: organization?.defaultPriceConfigDefaultSlope,
        })
    }, [form, organization]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="defaultPriceConfigDefaultMinPrice"
                label="Default min price"
            >
                <InputNumber
                    min={1}
                />
            </Form.Item>
            <Form.Item
                name="defaultPriceConfigDefaultDelta"
                label="Default delta"
            >
                <InputNumber
                    min={1}
                />
            </Form.Item>
            <Form.Item
                name="defaultPriceConfigDefaultSlope"
                label="Default slope"
            >
                <PriceConfigSlopeSelect />
            </Form.Item>
        </Form>
    );
}