import { ApolloError, gql, useMutation, useQuery } from "@apollo/client";
import { Alert, Col, Form, message, Row, Skeleton } from "antd";
import OnboardingItem from "./OnboardingItem";
import Modal from "antd/lib/modal/Modal";
import { useState } from "react";
import { SaveOutlined } from "@ant-design/icons";
import ApartmentLocationSettingsForm from "apartments/forms/ApartmentLocationSettingsForm";
import ApartmentCalendarSettingsForm from "apartments/forms/ApartmentCalendarSettingsForm";
import { taskPromise } from "common/task";
import ApartmentTagsForm from "apartments/forms/ApartmentTagsForm";
import ApartmentOnboardingPricingForm from "apartments/forms/ApartmentPricingSettingsForm";
import ApartmentOnboardingChannels from "../apartment-onboarding-channels/ApartmentOnboardingChannels";
import ApartmentOpenCalendarForm from "apartments/forms/ApartmentOpenCalendarForm";
import ApartmentOnboardingMetadataFullForm from "apartments/forms/ApartmentOnboardingMetadataFullForm";
import ApartmentOccupancySettingsForm from "apartments/forms/ApartmentOccupancySettingsForm";
import ApartmentAvailabilitySettingsForm from "apartments/forms/ApartmentAvailabilitySettingsForm";

const FORM_LAYOUT = {
    labelCol: {
        span: 6,
    },
    wrapperCol: {
        span: 18,
    },
};

const QUERY = gql`
    query GetApartmentSatteForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            name
            tags
            address
            latitude
            longitude
            storage {
                id
            }
            timezone
            calendarLength
            maxAvailability
            maxGuestCount
            cutOffTime
            weekdayMinStay
            cleaningPrice
            currency
            defaultPrice
            occupancyPriceModifiers
            onboardingTagsDone
            onboardingMetadataDone
            onboardingLocationDone
            onboardingCalendarDone
            onboardingOccupancyDone
            onboardingAvailabilityDone
            onboardingPricingDone
            onboardingChannelsDone
            onboardingOpenCalendarDone
        }
    }
`;

const MUTATION_ONBOARDING = gql`
    mutation UpdateApartmentOnboarding($input: UpdateApartmentOnboardingInput!) {
        updateApartmentOnboarding(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                onboardingTagsDone
                onboardingMetadataDone
                onboardingLocationDone
                onboardingCalendarDone
                onboardingOccupancyDone
                onboardingAvailabilityDone
                onboardingPricingDone
                onboardingChannelsDone
                onboardingOpenCalendarDone
            }
        }
    }
`;

const MUTATION_TAGS = gql`
    mutation UpdateApartmentTags($input: UpdateApartmentTagsInput!) {
        updateApartmentTags(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                tags
                onboardingTagsDone
            }
        }
    }
`;

const MUTATION_METADATA = gql`
    mutation CreateApartmentMetadata($input: CreateApartmentMetadataInput!) {
        createApartmentMetadata(input: $input) {
            error {
                type
                message
            }
        }
    }
`;

const MUTATION_LOCATION_SETTINGS = gql`
    mutation UpdateApartmentLocationOnboarding($input: UpdateApartmentLocationSettingsInput!) {
        updateApartmentLocationSettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                address
                latitude
                longitude
                storage {
                    id
                }
                timezone
                onboardingLocationDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

const MUTATION_CALENDAR_SETTINGS = gql`
    mutation UpdateApartmentCalendarOnboarding($input: UpdateApartmentCalendarSettingsInput!) {
        updateApartmentCalendarSettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                calendarLength
                onboardingCalendarDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

const MUTATION_OCCUPANCY_SETTINGS = gql`
    mutation UpdateApartmentOccupancyOnboarding($input: UpdateApartmentOccupancySettingsInput!) {
        updateApartmentOccupancySettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                maxGuestCount
                onboardingCalendarDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

const MUTATION_AVAILABILITY_SETTINGS = gql`
    mutation UpdateApartmentAvailabilityOnboarding($input: UpdateApartmentAvailabilitySettingsInput!) {
        updateApartmentAvailabilitySettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                maxAvailability
                cutOffTime
                weekdayMinStay
                onboardingCalendarDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

const MUTATION_PRICING_SETTINGS = gql`
    mutation UpdateApartmentPricingOnboarding($input: UpdateApartmentPricingSettingsInput!) {
        updateApartmentPricingSettings(input: $input) {
            error {
                type
                message
            }
            apartment {
                id
                cleaningPrice
                currency
                defaultPrice
                occupancyPriceModifiers
                onboardingPricingDone
            }
            syncApartmentTask {
                id
            }
        }
    }
`;

const MUTATION_OPEN_CALENDAR = gql`
    mutation OpenApartmentCalendar($input: OpenApartmentCalendarInput!) {
        openApartmentCalendar(input: $input) {
            error {
                type
                message
            }
            openApartmentCalendarTask {
                id
            }
        }
    }
`;

export default function ApartmentOnboarding(props) {
    const {
        apartmentId,
    } = props;

    const [tagsModalOpen, setTagsModalOpen] = useState(false);
    const [metadataModalOpen, setMetadataModalOpen] = useState(false);
    const [locationModalOpen, setLocationModalOpen] = useState(false);
    const [calendarModalOpen, setCalendarModalOpen] = useState(false);
    const [occupancyModalOpen, setOccupancyModalOpen] = useState(false);
    const [availabilityModalOpen, setAvailabilityModalOpen] = useState(false);
    const [pricingModalOpen, setPricingModalOpen] = useState(false);
    const [channelsModalOpen, setChannelsModalOpen] = useState(false);
    const [openCalendarModalOpen, setOpenCalendarModalOpen] = useState(false);

    const [updateTagsLoading, setUpdateTagsLoading] = useState(false);
    const [updateMetadataLoading, setUpdateMetadataLoading] = useState(false);
    const [updateLocationSettingsLoading, setUpdateLocationSettingsLoading] = useState(false);
    const [updateCalendarSettingsLoading, setUpdateCalendarSettingsLoading] = useState(false);
    const [updateOccupancySettingsLoading, setUpdateOccupancySettingsLoading] = useState(false);
    const [updateAvailabilitySettingsLoading, setUpdateAvailabilitySettingsLoading] = useState(false);
    const [updatePricingSettingsLoading, setUpdatePricingSettingsLoading] = useState(false);
    const [openCalendarLoading, setOpenCalendarLoading] = useState(false);

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });
    const [updateApartmentOnboarding] = useMutation(MUTATION_ONBOARDING);
    const [updateApartmentTags] = useMutation(MUTATION_TAGS);
    const [createApartmentMetadata] = useMutation(MUTATION_METADATA);
    const [updateApartmentLocation] = useMutation(MUTATION_LOCATION_SETTINGS);
    const [updateApartmentCalendar] = useMutation(MUTATION_CALENDAR_SETTINGS);
    const [updateApartmentOccupancy] = useMutation(MUTATION_OCCUPANCY_SETTINGS);
    const [updateApartmentAvailability] = useMutation(MUTATION_AVAILABILITY_SETTINGS);
    const [updateApartmentPricing] = useMutation(MUTATION_PRICING_SETTINGS);
    const [openApartmentCalendar] = useMutation(MUTATION_OPEN_CALENDAR);

    const [tagsForm] = Form.useForm();
    const [metadataForm] = Form.useForm();
    const [locationForm] = Form.useForm();
    const [calendarForm] = Form.useForm();
    const [occupancyForm] = Form.useForm();
    const [availabilityForm] = Form.useForm();
    const [pricingForm] = Form.useForm();
    const [openCalendarForm] = Form.useForm();

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load onboarding data"
            />
        );
    }

    const setupTagsEnabled = true;
    const setupMetadataEnabled = true;
    const setupLocationEnabled = true;
    const setupCalendarEnabled = true;
    const setupOccupancyEnabled = true;
    const setupAvailabilityEnabled = true;
    const setupPricingEnabled = data?.apartment?.onboardingCalendarDone && data?.apartment?.onboardingOccupancyDone && data?.apartment?.onboardingAvailabilityDone;
    const setupChannelsEnabled = data?.apartment?.onboardingCalendarDone && data?.apartment?.onboardingOccupancyDone && data?.apartment?.onboardingAvailabilityDone && data?.apartment?.onboardingPricingDone;
    const openCalendarEnabled = data?.apartment?.onboardingCalendarDone && data?.apartment?.onboardingOccupancyDone && data?.apartment?.onboardingAvailabilityDone && data?.apartment?.onboardingPricingDone;

    async function handleTagsSave() {
        try {
            const values = await tagsForm.validateFields();

            setUpdateTagsLoading(true)

            const updateTagsResponse = await updateApartmentTags({
                variables: {
                    input: {
                        apartmentId,
                        tags: values.tags,
                    },
                },
            });

            if (updateTagsResponse?.data?.updateApartmentTags?.error) {
                message.error("Failed to update apartment tags");
                setUpdateTagsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingTagsDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateTagsLoading(false);
                return;
            }

            message.success("Apartment tags updated");
            setUpdateTagsLoading(false);
            setTagsModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateTagsLoading(false);
        }
    }

    async function handleMetadataSave() {
        try {
            const values = await metadataForm.validateFields();

            setUpdateMetadataLoading(true);

            const createApartmentMetadataResults = await Promise.all(
                Object.entries(values)
                    .map(([apartmentMetadataTypeId, value]) => {
                        return createApartmentMetadata({
                            variables: {
                                input: {
                                    apartmentId,
                                    apartmentMetadataTypeId,
                                    value,
                                },
                            },
                        });
                    })
            );

            if (createApartmentMetadataResults.some(result => result?.data?.createApartmentMetadata?.error)) {
                message.error("Failed to create apartment metadata");
                setUpdateMetadataLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingMetadataDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateMetadataLoading(false);
                return;
            }

            message.success("Apartment metadata updated");
            setUpdateMetadataLoading(false);
            setMetadataModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateMetadataLoading(false);
        }
    }

    async function handleLocationSave() {
        try {
            const values = await locationForm.validateFields();

            setUpdateLocationSettingsLoading(true);

            const updateLocationResponse = await updateApartmentLocation({
                variables: {
                    input: {
                        apartmentId,
                        address: values.location.address,
                        latitude: values.location.latitude,
                        longitude: values.location.longitude,
                        storageId: values.storageId,
                        timezone: values.timezone,
                    },
                },
            });

            if (updateLocationResponse?.data?.updateApartmentLocationSettings?.error) {
                message.error("Failed to update apartment location");
                setUpdateLocationSettingsLoading(false);
                return;
            }

            try {
                await taskPromise(updateLocationResponse?.data?.updateApartmentLocationSettings?.syncApartmentTask?.id);
            }
            catch {
                message.error("Failed to update apartment location");
                setUpdateLocationSettingsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingLocationDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateLocationSettingsLoading(false);
                return;
            }

            message.success("Apartment location updated");
            setUpdateLocationSettingsLoading(false);
            setLocationModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateLocationSettingsLoading(false);
        }
    }

    async function handleCalendarSave() {
        try {
            const values = await calendarForm.validateFields();

            setUpdateCalendarSettingsLoading(true);

            const updateCalendarResponse = await updateApartmentCalendar({
                variables: {
                    input: {
                        apartmentId,
                        calendarLength: values.calendarLength,
                    },
                },
            });

            if (updateCalendarResponse?.data?.updateApartmentCalendarSettings?.error) {
                message.error("Failed to update apartment calendar");
                setUpdateCalendarSettingsLoading(false);
                return;
            }

            try {
                await taskPromise(updateCalendarResponse?.data?.updateApartmentCalendarSettings?.syncApartmentTask?.id);
            }
            catch {
                message.error("Failed to update apartment calendar");
                setUpdateCalendarSettingsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingCalendarDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateCalendarSettingsLoading(false);
                return;
            }

            message.success("Apartment calendar updated");
            setUpdateCalendarSettingsLoading(false);
            setCalendarModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateCalendarSettingsLoading(false);
        }
    }

    async function handleOccupancySave() {
        try {
            const values = await occupancyForm.validateFields();

            setUpdateOccupancySettingsLoading(true);

            const updateOccupancyResponse = await updateApartmentOccupancy({
                variables: {
                    input: {
                        apartmentId,
                        maxGuestCount: values.maxGuestCount,
                    },
                },
            });

            if (updateOccupancyResponse?.data?.updateApartmentOccupancySettings?.error) {
                message.error("Failed to update apartment occupancy");
                setUpdateOccupancySettingsLoading(false);
                return;
            }

            try {
                await taskPromise(updateOccupancyResponse?.data?.updateApartmentOccupancySettings?.syncApartmentTask?.id);
            }
            catch {
                message.error("Failed to update apartment occupancy");
                setUpdateOccupancySettingsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingOccupancyDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateOccupancySettingsLoading(false);
                return;
            }

            message.success("Apartment occupancy updated");
            setUpdateOccupancySettingsLoading(false);
            setOccupancyModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateOccupancySettingsLoading(false);
        }
    }

    async function handleAvailabilitySave() {
        try {
            const values = await availabilityForm.validateFields();

            setUpdateAvailabilitySettingsLoading(true);

            const updateAvailabilityResponse = await updateApartmentAvailability({
                variables: {
                    input: {
                        apartmentId,
                        maxAvailability: values.unitSize.choice === 'single' ? 1 : values.unitSize.value,
                        cutOffTime: values.cutOffTime,
                        weekdayMinStay: values.weekdayMinStay,
                    },
                },
            });

            if (updateAvailabilityResponse?.data?.updateApartmentAvailabilitySettings?.error) {
                message.error("Failed to update apartment availability");
                setUpdateAvailabilitySettingsLoading(false);
                return;
            }

            try {
                await taskPromise(updateAvailabilityResponse?.data?.updateApartmentAvailabilitySettings?.syncApartmentTask?.id);
            }
            catch {
                message.error("Failed to update apartment availability");
                setUpdateAvailabilitySettingsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingAvailabilityDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error("Failed to update apartment onboarding");
                setUpdateAvailabilitySettingsLoading(false);
                return;
            }

            message.success("Apartment availability updated");
            setUpdateAvailabilitySettingsLoading(false);
            setAvailabilityModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdateAvailabilitySettingsLoading(false);
        }
    }

    async function handlePricingSave() {
        try {
            const values = await pricingForm.validateFields();
            setUpdatePricingSettingsLoading(true);

            const updatePricingResponse = await updateApartmentPricing({
                variables: {
                    input: {
                        apartmentId,
                        cleaningPrice: values.cleaningPrice,
                        currency: values.currency,
                        defaultPrice: values.defaultPrice,
                        occupancyPriceModifiers: values.occupancyPriceModifiers,
                    },
                },
            });

            if (updatePricingResponse?.data?.updateApartmentPricingSettings?.error) {
                message.error("Failed to update apartment pricing");
                setUpdatePricingSettingsLoading(false);
                return;
            }

            try {
                await taskPromise(updatePricingResponse?.data?.updateApartmentPricingSettings?.syncApartmentTask?.id);
            }
            catch {
                message.error(`Failed to update apartment pricing`);
                setUpdatePricingSettingsLoading(false);
                return;
            }

            const updateOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingPricingDone: true,
                    },
                },
            });

            if (updateOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error(`Failed to update apartment onboarding`);
                setUpdatePricingSettingsLoading(false);
                return;
            }

            message.success("Apartment pricing updated");
            setUpdatePricingSettingsLoading(false);
            setPricingModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setUpdatePricingSettingsLoading(false);
        }
    }

    function handleChannelOnboardingConnected() {
        updateApartmentOnboarding({
            variables: {
                input: {
                    apartmentId,
                    onboardingChannelsDone: true,
                },
            },
        });
    }

    async function handleOpenCalendarSave() {
        try {
            const values = await openCalendarForm.validateFields();
            setOpenCalendarLoading(true);

            const openApartmentCalendarResponse = await openApartmentCalendar({
                variables: {
                    input: {
                        apartmentId,
                        availableFrom: values.availableFrom,
                    },
                },
            });

            if (openApartmentCalendarResponse?.data?.openApartmentCalendar?.error) {
                message.error("Failed to open calendar");
                setOpenCalendarLoading(false);
                return;
            }

            try {
                await taskPromise(openApartmentCalendarResponse?.data?.openApartmentCalendar?.openApartmentCalendarTask?.id);
            }
            catch {
                message.error(`Failed to open calendar`);
                setOpenCalendarLoading(false);
                return;
            }

            const updateOpenApartmentCalendarOnboardingResponse = await updateApartmentOnboarding({
                variables: {
                    input: {
                        apartmentId,
                        onboardingOpenCalendarDone: true,
                    },
                },
            });

            if (updateOpenApartmentCalendarOnboardingResponse?.data?.updateApartmentOnboarding?.error) {
                message.error(`Failed to update apartment onboarding`);
                setOpenCalendarLoading(false);
                return;
            }

            message.success("Apartment calendar opened");
            setOpenCalendarLoading(false);
            setOpenCalendarModalOpen(false);
        }
        catch (e) {
            if (e instanceof ApolloError) {
                message.error("Network error");
            }
            setOpenCalendarLoading(false);
        }
    }

    return (
        <Row gutter={[16, 16]}>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingTagsDone}
                    label="Setup tags"
                    disabled={!setupTagsEnabled}
                    onClick={() => setTagsModalOpen(true)}
                />
                <Modal
                    open={tagsModalOpen}
                    title="Setup tags"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateTagsLoading,
                    }}
                    onOk={() => handleTagsSave()}
                    onCancel={() => setTagsModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentTagsForm
                        form={tagsForm}
                        preserve={false}
                        apartment={data?.apartment}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingMetadataDone}
                    label="Setup metadata"
                    disabled={!setupMetadataEnabled}
                    onClick={() => setMetadataModalOpen(true)}
                />
                <Modal
                    open={metadataModalOpen}
                    title="Setup metadata"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateMetadataLoading,
                    }}
                    onOk={() => handleMetadataSave()}
                    onCancel={() => setMetadataModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentOnboardingMetadataFullForm
                        form={metadataForm}
                        preserve={false}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingLocationDone}
                    label="Setup location"
                    disabled={!setupLocationEnabled}
                    onClick={() => setLocationModalOpen(true)}
                />
                <Modal
                    open={locationModalOpen}
                    title="Setup location"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateLocationSettingsLoading,
                    }}
                    onOk={() => handleLocationSave()}
                    onCancel={() => setLocationModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentLocationSettingsForm
                        form={locationForm}
                        preserve={false}
                        apartment={data?.apartment}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingCalendarDone}
                    label="Setup calendar"
                    disabled={!setupCalendarEnabled}
                    onClick={() => setCalendarModalOpen(true)}
                />
                <Modal
                    open={calendarModalOpen}
                    title="Setup calendar"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateCalendarSettingsLoading,
                    }}
                    onOk={() => handleCalendarSave()}
                    onCancel={() => setCalendarModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentCalendarSettingsForm
                        form={calendarForm}
                        preserve={false}
                        apartment={data?.apartment}
                        initialValues={{
                            weekdayMinStay: [1, 1, 1, 1, 1, 1, 1],
                        }}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingOccupancyDone}
                    label="Setup occupancy"
                    disabled={!setupOccupancyEnabled}
                    onClick={() => setOccupancyModalOpen(true)}
                />
                <Modal
                    open={occupancyModalOpen}
                    title="Setup occupancy"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateOccupancySettingsLoading,
                    }}
                    onOk={() => handleOccupancySave()}
                    onCancel={() => setOccupancyModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentOccupancySettingsForm
                        form={occupancyForm}
                        preserve={false}
                        apartment={data?.apartment}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingAvailabilityDone}
                    label="Setup availability"
                    disabled={!setupAvailabilityEnabled}
                    onClick={() => setAvailabilityModalOpen(true)}
                />
                <Modal
                    open={availabilityModalOpen}
                    title="Setup availability"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updateAvailabilitySettingsLoading,
                    }}
                    onOk={() => handleAvailabilitySave()}
                    onCancel={() => setAvailabilityModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentAvailabilitySettingsForm
                        form={availabilityForm}
                        preserve={false}
                        apartment={data?.apartment}
                        initialValues={{
                            weekdayMinStay: [1, 1, 1, 1, 1, 1, 1],
                        }}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingPricingDone}
                    label="Setup pricing"
                    disabled={!setupPricingEnabled}
                    onClick={() => setPricingModalOpen(true)}
                />
                <Modal
                    open={pricingModalOpen}
                    title="Setup pricing"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: updatePricingSettingsLoading,
                    }}
                    onOk={() => handlePricingSave()}
                    onCancel={() => setPricingModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentOnboardingPricingForm
                        form={pricingForm}
                        apartment={data?.apartment}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingChannelsDone}
                    label="Setup channels"
                    disabled={!setupChannelsEnabled}
                    onClick={() => setChannelsModalOpen(true)}
                />
                <Modal
                    open={channelsModalOpen}
                    title="Setup channels"
                    okText="Save"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                    }}
                    onOk={() => setChannelsModalOpen(false)}
                    onCancel={() => setChannelsModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentOnboardingChannels
                        apartmentId={apartmentId}
                        onConnected={() => handleChannelOnboardingConnected()}
                    />
                </Modal>
            </Col>
            <Col span={24}>
                <OnboardingItem
                    done={data.apartment.onboardingOpenCalendarDone}
                    label="Open calendar"
                    disabled={!openCalendarEnabled}
                    onClick={() => setOpenCalendarModalOpen(true)}
                />
                <Modal
                    open={openCalendarModalOpen}
                    title="Open calendar"
                    okText="Open calendar"
                    okButtonProps={{
                        icon: <SaveOutlined />,
                        loading: openCalendarLoading,
                    }}
                    onOk={() => handleOpenCalendarSave()}
                    onCancel={() => setOpenCalendarModalOpen(false)}
                    destroyOnClose
                    width={700}
                >
                    <ApartmentOpenCalendarForm
                        form={openCalendarForm}
                        {...FORM_LAYOUT}
                    />
                </Modal>
            </Col>
        </Row>
    );
}