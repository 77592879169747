import { PlusOutlined } from "@ant-design/icons";
import { Button, Card, PageHeader } from "antd";
import ChecklistsList from "checklists/components/checklists-list/ChecklistsList";
import { Link, useNavigate } from "react-router-dom";

export default function ListChecklistsView() {
    const navigate = useNavigate();

    return (
        <PageHeader
            title="Checklists"
            onBack={() => navigate(-1)}
            extra={[
                <Link
                    to={`/checklists/create`}
                    key="create"
                >
                    <Button
                        type="primary"
                        icon={<PlusOutlined />}
                    >
                        Create checklist
                    </Button>
                </Link>
            ]}
        >
            <Card>
                <ChecklistsList />
            </Card>
        </PageHeader>
    );
}