import { gql, useQuery } from "@apollo/client";
import { Alert, List, Skeleton } from "antd";
import ListItem from "./ListItem";

const QUERY = gql`
    query GetApartmentChannelsForOnboarding($apartmentId: ID!) {
        apartment(apartmentId: $apartmentId) {
            id
            channels {
                id
                otaName
                config
                active
                createdAt
            }
        }
    }
`;


export default function ApartmentChannelsList(props) {
    const {
        apartmentId,
        editable,
    } = props;

    const { data, loading, error } = useQuery(QUERY, { variables: { apartmentId } });

    if (loading) {
        return (
            <Skeleton />
        );
    }

    if (error) {
        return (
            <Alert
                type="error"
                showIcon
                message="Failed to load channels"
            />
        );
    }

    const channels = [...data?.apartment?.channels ?? []]
        .sort((a, b) => a.id - b.id);

    if (channels.length === 0) {
        return (
            <Alert
                type="info"
                showIcon
                message="No channels"
            />
        );
    }

    return (
        <List
            dataSource={channels}
            renderItem={channel => (
                <ListItem
                    apartmentId={apartmentId}
                    channel={channel}
                    editable={editable}
                />
            )}
        />
    );
}