import { Form, Input } from "antd";
import InputFieldDefinition from "components/input-field-definition/InputFieldDefinition";
import TranslationsInput from "components/translations-input/TranslationsInput";
import { useEffect } from "react";

export default function ChecklistItemForm(props) {
    const {
        form,
        checklistItem,
        ...otherProps
    } = props;

    useEffect(() => {
        form?.setFieldsValue({
            name: checklistItem?.name,
            nameTranslations: checklistItem?.nameTranslations,
            typeConfig: checklistItem?.typeConfig,
        })
    }, [form, checklistItem]);

    return (
        <Form
            form={form}
            {...otherProps}
        >
            <Form.Item
                name="name"
                label="Name"
                rules={[{ required: true, message: 'Name is required' }]}
            >
                <Input />
            </Form.Item>
            <Form.Item
                name="nameTranslations"
                label="Name translations"
            >
                <TranslationsInput />
            </Form.Item>
            <Form.Item
                name="typeConfig"
                label="Field definition"
            >
                <InputFieldDefinition />
            </Form.Item>
        </Form>
    );
}